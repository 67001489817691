<template>
  <a
    class="u-initiative-route-card"
    :class="{
      'u-initiative-route-card--without-img': !formattedCard.image,
      'u-initiative-route-card--without-cover': !formattedCard.tag && !formattedCard.image,
    }"
    :href="formattedCard.url"
    :target="formattedCard.target"
  >
    <div class="u-initiative-route-card__body">
      <p class="u-initiative-route-card__city tt-uppercase">
        {{ formattedCard.city }}
      </p>
      <div class="u-initiative-route-card__content">
        <h3
          class="u-initiative-route-card__title title-lg tt-uppercase"
          v-html="formattedCard.title"
        />
        <p
          class="u-initiative-route-card__description"
          v-html="formattedCard.description"
        />
        <CustomButton
          size="sm"
          theme="outline-light"
        >
          Забронировать тур
        </CustomButton>
      </div>
    </div>
    <div v-if="formattedCard.tag || formattedCard.image" class="u-initiative-route-card__cover">
      <span v-if="formattedCard.tag" class="u-initiative-route-card__tag">
        {{ formattedCard.tag }}
      </span>
      <img
        v-if="formattedCard.image"
        class="u-initiative-route-card__image"
        :src="formattedCard.image"
        :alt="formattedCard.title"
      >
    </div>
  </a>
</template>

<script>
export default {
  name: "UInitiativeRouteCard",
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    formattedCard() {
      const {data} = this;

      return {
        ...data,
        tag: data.tag || '',
        image: data.image || '',
        city: data.city || '',
        title: data.title || 'Путешествие',
        description: data.description || '',
        url: data.url || '#',
        target: data.target || '_self',
      };
    },
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-initiative-route-card';

#{$b} {
  background: $color-base;
  color: $white-true;
  border-radius: 16px;
  display: grid;
  grid-template-columns: auto 258px;
  min-height: 394px;
  text-decoration: none;
  overflow: hidden;

  @include low-desktop {
    grid-template-columns: auto 42.43%;
    min-height: 294px;
  }

  @include mobile {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__body {
    padding: 24px;
    display: flex;
    flex-direction: column;

    @include mobile {
      order: 1;
    }
  }

  &__content {
    margin-top: auto;
  }

  &__cover {
    border-radius: 16px;
    height: 100%;
    overflow: hidden;
    position: relative;

    @include mobile {
      display: flex;
      aspect-ratio: 376 / 200;
      order: -1;
      padding: 17px 22px;
    }
  }

  &__tag {
    position: absolute;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    color: $white-true;
    text-transform: uppercase;
    background-color: $color-light-blue;
    z-index: 2;
    top: 14px;
    left: 20px;
    padding: 9px 8px 7px;
    border-radius: 8px;

    #{$b}--without-img & {
      background-color: $white-true;
      color: $color-light-blue;
    }

    @include mobile {
      font-size: 14px;
      position: relative;
      top: 0;
      left: 0;
      margin-top: auto;
    }
  }

  &__image {
    height: 100%;
    max-width: none;
    max-height: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include mobile {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }

  &__city, &__title, &__description {
    margin: 0; // reset default margins
  }

  &__city {
    line-height: 1.3;
    font-weight: 500;
    margin-bottom: 28px;

    @include mobile {
      #{$b}:not(#{$b}--without-cover) & {
        position: absolute;
        top: 17px;
        left: 22px;
        z-index: 10;
      }
    }
  }

  &__title {
    font-family: $font-family-fugue;
    margin-bottom: 12px;
    font-weight: 600;
  }

  &__description {
    margin-bottom: 24px;
    line-height: 1.3;
  }
}
</style>
