<template>
  <div class="lk-plan-project">
    <div class="lk-plan-project__header">
      <slot />
    </div>
    <div class="lk-plan-project__body">
      <div
        v-for="(item, i) in list"
        :key="`lk-plan-project__list-${i}`"
        class="lk-plan-project__list"
      >
        <h3 class="lk-plan-project__list-title tt-uppercase">
          {{ item.title }}
        </h3>
        <PrimaryList :list="item.list"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.lk-plan-project';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;

  // .lk-plan-project__header
  &__header {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.5;
    margin: 22px 0 64px;

    @include mobile {
      font-size: 16px;
      line-height: 1.3;
      margin: 3px 0 24px 0;
    }
  }

  // .lk-plan-project__list
  &__list {
    &:not(:last-child) {
      margin-bottom: 64px;

      @include mobile {
        margin-bottom: 24px;
      }
    }

    @include mobile-min {
      .primary-item__inner {
        min-height: 248px;
      }
    }

    // .lk-plan-project__list-title
    &-title {
      font-family: $font-family-fugue;
      font-size: 28px;
      font-weight: 600;
      line-height: 100%;
      margin: 0 0 32px 0;

      @include mobile {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 24px;
      }
    }
  }
}
</style>
