<template>
  <div v-if="isExistsInitialData" class="partner-logo">
    <img v-if="initialData.img" :src="initialData.img" class="partner-logo__img">
    <div v-else class="partner-logo__text tt-uppercase">
      {{ initialData.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialData: {
      type: Object,
      required: true
    }
  },
  computed: {
    isExistsInitialData() {
      return Object.keys(this.initialData).length > 0
    }
  }
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.partner-logo';

#{$b} {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-fugue;
  font-weight: 500;
  font-size: 9px;
  text-align: center;
  background-color: rgba($white-true, 0.1);
  border-radius: 16px;
  padding: 16px;

  @include mobile {
    padding: 15px;
  }

  // .partner-logo__img
  &__img {
    height: 44px;
    min-height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
    object-position: center;

    @include mobile {
      height: 41px;
    }
  }
}
</style>
