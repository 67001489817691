<template>
  <section class="talisman-intro" :style="`background-image: url(${getBackground})`">
    <div class="u-g-container">
      <div class="talisman-intro__inner">
        <!-- скрыт -->
        <!-- <div class="talisman-intro__header">
          <div class="talisman-intro__header-timelaps">
            <TalismanTimelaps :timelapsInitial="timelapsInitial"/>
          </div>
        </div> -->
        <div class="talisman-intro__body">
          <div class="talisman-intro__info">
            <h1
              class="talisman-intro__title tt-uppercase"
              :class="`talisman-intro__title--${titleSize} title-${titleSize}`"
              v-html="title"
            />
            <p class="talisman-intro__description text-lg" v-html="description"/>
          </div>
        </div>
        <div v-if="isExistsBtnList" class="talisman-intro__actions">
          <div class="talisman-intro__actions-list">
            <div
              class="talisman-intro__actions-item"
              v-for="btn in btnList"
              :key="`talisman-intro__actions-item-${btn.name}-${btn.url}`"
            >
              <CustomButton
                theme="primary"
                size="md"
                class="talisman-intro__actions-btn"
                tag="a"
                :href="btn.url"
                @click="onClick(btn)"
              >
                <span v-html="btn.name" />
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  inject: ['breakpoint', 'ymHandler'],
  props: {
    title: {
      type:String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    timelapsInitial: {
      type: Object,
      default: () => ({})
    },
    background: {
      type: Object,
      default: () => ({
        desktop: '',
        tablet: '',
        mobile: '',
      })
    },
    titleSize: {
      type: String,
      default: '2xl' // 2xl | 2lg
    },
    btnList: {
      type: Array,
      default: () => []
    },

  },
  computed: {
    isExistsBtnList() {
      return this.btnList && this.btnList.length
    },
    getBackground() {
      let background = ''

      if (this.breakpoint.isDesktop && this.background.desktop) {
        background = this.background.desktop
      } else if (this.breakpoint.isTablet && this.background.tablet) {
        background = this.background.tablet
      } else if (this.breakpoint.isMobile && this.background.mobile) {
        background = this.background.mobile
      } else {
        background = Object.values(this.background).find(item => !!item) || ''
      }

      return background
    }
  },
  methods: {
    scrollToRegulations() {
      this.$root.$emit('scrollToRegulations')
    },
    onClick(btn) {
      if (!btn.ym || !Object.values(btn.ym).length) {
        return
      }

      this.ymHandler({ code: btn.ym.code, type: btn.ym.type ,operation: btn.ym.operation })
    },
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-intro';

#{$b} {
  // background-position: center right;
  background-position: center;

  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
  color: $white-true;
  font-family: $font-family-inter;

  @include low-desktop {
    padding-top: 90px;
  }

  @include mobile {
    padding: 110px 0 32px 0;
  }

  // .talisman-intro__inner
  &__inner {
    display: flex;
    flex-direction: column;
    min-height: 640px;

    @include low-desktop {
      min-height: 500px;
    }

    @include mobile {
      min-height: 280px;
    }
  }

  // .talisman-intro__header
  &__header {
    display: flex;

    @include low-desktop {
      margin-bottom: 32px;
    }

    @include mobile {
      display: block;
    }

    // .talisman-intro__header-timelaps
    &-timelaps {
      margin-left: auto;
    }
  }

  // .talisman-intro__body
  &__body {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  // .talisman-intro__info
  &__info {
    max-width: 967px;
  }

  // .talisman-intro__title
  &__title {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  // .talisman-intro__description
  &__description {
    margin: 0;

    @include mobile {
      font-size: 14px;
      line-height: 130%;
    }
  }

  // .talisman-intro__actions
  &__actions {
    margin-top: 24px;

    // .talisman-intro__actions-list
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -8px;

      @include mobile {
        display: block;
        margin: 0;
      }
    }
    // .talisman-intro__actions-item
    &-item {
      padding: 8px;

      @include mobile {
        padding: 0;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    // .talisman-intro__actions-btn
    &-btn {
      @include mobile {
        word-wrap: normal;
        white-space: unset;

        & * {
          word-wrap: normal;
          white-space: unset;
        }
      }
    }

    // .talisman-intro__actions-suggest ,
    // .talisman-intro__actions-btn
    &-btn,
    &-suggest
     {
      @include mobile {
        width: 100%;
      }
    }
  }
}
</style>
