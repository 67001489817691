<template>
  <div class="excursions-subscribe" :class="{'excursions-subscribe--validate-error': isValidateError}">
    <UFigures
      :items="figures"
      position="static"
      :isAnimateImg="false"
      :fullWidth="true"
      startPosition="center bottom"
    >
      <div class="excursions-subscribe__inner">
        <template v-if="!isSubscribeFromStorage">
          <h3 class="excursions-subscribe__title tt-uppercase" v-html="title" />
          <p v-if="successText">
            {{ successText }}
          </p>
        </template>
        <template v-else>
          <h3 class="excursions-subscribe__title excursions-subscribe__title--solo tt-uppercase">
            Вы&nbsp;уже подписаны, узнавайте о&nbsp;новых экскурсиях и&nbsp;лекциях каждый месяц 1-го&nbsp;числа
          </h3>
        </template>
        <div v-if="!isSubscribeFromStorage" class="excursions-subscribe__form">
          <div class="excursions-subscribe__form-field">
            <input
              type="text"
              class="excursions-subscribe__form-input"
              placeholder="Ваш e-mail"
              v-model="email"
            >
            <ErrorFormWrapper
              :isVisible="isValidateError"
              class="excursions-subscribe__error-field"
            >
              <div class="excursions-subscribe__error-text" v-show="!this.$v.email.required">
                <inline-svg class="excursions-subscribe__error-icon" src="error-icon"/> Обязательное поле
              </div>
              <div class="excursions-subscribe__error-text" v-show="!this.$v.email.email">
                <inline-svg class="excursions-subscribe__error-icon" src="error-icon"/> Неправильный e-mail
              </div>
              <div class="excursions-subscribe__error-text" v-show="!this.$v.email.isGmail">
                <inline-svg class="excursions-subscribe__error-icon" src="error-icon"/> Укажите почту в российской зоне .ru
              </div>
            </ErrorFormWrapper>
          </div>
          <CustomButton
            class="excursions-subscribe__form-btn"
            theme="light"
            size="ll"
            :status="btnStatus"
            @click="onSubmit"
          >
            Подписаться
          </CustomButton>
        </div>
        <ErrorFormWrapper
          class="excursions-subscribe__feedback-errors"
          :max-height="1000"
          :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
        >
          <div
            v-for="error in errorsAfterSubmit"
            :key="`excursions-subscribe__feedback-error-${error.code}`"
            class="excursions-subscribe__feedback-error"
          >
            <span v-html="error.message" />
          </div>
        </ErrorFormWrapper>
      </div>
    </UFigures>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { checkGmail } from '/frontend/components/helpers/validate.js'

export default {
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      defualt: '',
    },
    actionSubmit: {
      type: String,
      defualt: ''
    }
  },
  data() {
    return {
      figures: [
        {
          name: 'f-excursions-subscribe-1',
          image: '/frontend/assets/img/excursions/circle-group-line-2-light.svg',
          animation: 'fade-in-left'
        },
        {
          name: 'f-excursions-subscribe-mob',
          image: '/frontend/assets/img/excursions/subscribe-mob.svg',
          animation: 'fade-in-left'
        },
      ],
      email: '',
      btnStatus: '',
      successText: '',
      errorsAfterSubmit: [],
      storageKey: 'isExcursionsSubscribe',
      isSubscribeFromStorage: false
    }
  },
  validations: {
    email: { required, email, isGmail: checkGmail },
  },
  created() {
    this.checkSubscribeFromStorage()
  },
  computed: {
    isValidateError() {
      return this.$v.email.$error && (!this.$v.email.email || !this.$v.email.required) || !this.$v.email.isGmail
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return
      }

      this.btnStatus = 'loading'

      const data = new FormData()
      data.append('email', this.email)

      const options = {
        url: this.actionSubmit,
        method: 'POST',
        data
      };

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.successResponse(response)
      } else {
        this.errorResponse(response)
      }
    },
    successResponse(response) {
      this.btnStatus = 'success';
      this.$v.$reset();
      this.email = '';
      this.errorsAfterSubmit = [];
      this.successText = response?.data?.data?.message;
      localStorage.setItem(this.storageKey, 'true')
      this.checkSubscribeFromStorage()
      this.btnStatus = '';
    },
    errorResponse(response) {
      this.btnStatus = ''
      this.errorsAfterSubmit = response.data.errors
    },
    isGmailErrorField() {
      return this.$v.email.$error && !this.$v.email.isGmail
    },
    checkSubscribeFromStorage() {
      this.isSubscribeFromStorage = !!localStorage.getItem(this.storageKey)
    }
  }
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.excursions-subscribe';

#{$b} {
  position: relative;
  color: $white-true;
  border-radius: 16px;
  background-color: $color-base-origin;
  overflow: hidden;

  &--validate-error {
    .error-form-wrapper--is-visible {
      margin-top: 9px;
    }
  }

  // .excursions-subscribe__inner
  &__inner {
    position: relative;
    padding: 32px 24px;
    transition: $transtion-default;

    #{$b}--validate-error & {
      padding-bottom: 42px;
    }

    @include mobile {
      padding: 24px;
    }
  }

  // .excursions-subscribe__title
  &__title {
    font-family: $font-family-fugue;
    font-size: 32px;
    line-height: 100%;
    margin: 0 0 32px 0;

    @include mobile {
      font-size: 28px;
      margin-bottom: 24px;
    }

    // .excursions-subscribe__title--solo
    &--solo {
      margin: 0;
    }
  }

  // .excursions-subscribe__form
  &__form {
    width: 100%;
    display: flex;

    @include mobile {
      flex-direction: column;
      align-items: center;
    }

    // .excursions-subscribe__form-field
    &-field {
      position: relative;
      width: 100%;
      margin-right: 24px;

      @include mobile {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    // .excursions-subscribe__form-input
    &-input {
      width: 100%;
      height: 40px;
      font-size: 16px;
      line-height: 120%;
      font-weight: 500;
      color: $black-true;
      background-color: $white-true;
      border-radius: 20px;
      padding: 8px 24px;
      border: none;
      transition: all 0.3s ease;
    }
  }

  // .excursions-subscribe__error-field
  &__error-field {
    font-size: 16px;
    line-height: 1.4;
    color: $white-true !important;

    @include mobile-min {
      position: absolute;
      top: 100%;
    }
  }

  // .excursions-subscribe__error-text
  &__error-text {
    display: flex;
    align-items: center;
  }

  // .excursions-subscribe__error-icon
  &__error-icon {
    max-height: 16px;
    max-width: 16px;
    margin-right: 8px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  // .excursions-subscribe__feedback-error
  &__feedback-error {
    font-size: 16px;
    line-height: 1.4;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
