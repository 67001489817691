<template>
  <div v-if="isActive" class="header-tab">
    <slot />
  </div>
</template>

<script>
  export default {
    name: "HeaderTab",
    props: {
      title: { type: String, default: '' },
      selected: { type: Boolean, default: false },
      tabId: { type: String, required: true },
    },
    data() {
      return {
        isActive: false
      };
    },
    methods: {
      openSavedTab() {
        const params = (new URL(document.location)).searchParams;
        const tabParam = params.get("tab")

        if (tabParam) {
          this.$nextTick(() => {
            this.$root.$emit('header-tabs-change', tabParam)
          })
        }
      }
    },
    mounted() {
      this.openSavedTab()
      this.isActive = this.selected;
    },
  };
</script>
