<template>
  <div class="talisman-contestants-verify">
    <div class="talisman-contestants-verify__caption text-base">
      Введите код из смс
    </div>
    <div class="talisman-contestants-verify__body" :style="`--colums: ${countFields}`">
      <input
        v-for="(field, i) in fields"
        :key="`talisman-contestants-verify__input-${field.id}`"
        v-model="field.value"
        maxlength="1"
        min="0"
        max="9"
        type="number"
        class="talisman-contestants-verify__input title-xl tt-uppercase"
        :class="{'talisman-contestants-verify__input--is-error': isRequiredErrorField(i)}"
        :ref="`field-${field.id}`"
        @input="(e) => onInput(e, field)"
        @keydown="(e) => onKeydown(e, field)"
        @beforeinput="onBeforeinput"
      >
    </div>
    <ErrorFormWrapper
      class="talisman-contestants-verify__feedback-errors"
      :max-height="1000"
      :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
    >
      <div
        v-for="error in errorsAfterSubmit"
        :key="`talisman-contestants-verify__feedback-error-${error.code}`"
        class="talisman-contestants-verify__feedback-error"
      >
        <span v-html="error.message" />
      </div>
    </ErrorFormWrapper>
    <div class="talisman-contestants-verify__update">
      <span v-if="time">СМС придет в течении 60 секунд. <br> Запросить новый код можно через {{ getTimeToRefreshCode }}с</span>
      <span v-else class="talisman-contestants-verify__update-btn" @click="updateCode">Запросить отправку смс еще раз</span>
    </div>
    <div class="talisman-contestants-verify__actions">
      <CustomButton
        theme="primary"
        size="md"
        class="talisman-contestants-verify__btn"
        :status="btnStatus"
        @click="onSubmit"
      >
        Отправить
      </CustomButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import formatNumberToString from '/frontend/components/helpers/formatNumberToString.js'

export default {
  mixins: [validationMixin],
  props: {
    actionVerifyCode: {
      type: String,
      default: '',
    },
    actionRefreshCode: {
      type: String,
      default: '',
    },
    workId: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      countFields: 6,
      fields: [],
      reg: /[^0-9]/g,
      isValidKeys: ['1','2','3','4','5','6','7','8','9','0'],
      btnStatus: '',
      time: 0,
      timeIntervalId: null,
      errorsAfterSubmit: []
    }
  },
  validations: {
    fields: {
      $each: {
        value: { required }
      }
    }
  },
  computed: {
    getFormData() {
      const data = new FormData()

      const code = this.fields
        .map(field => field.value)
        .join('')

      data.append('code', code)
      data.append('id', this.workId)
      data.append('phone', this.phone)

      return data
    },
    getTimeToRefreshCode() {
      return formatNumberToString(this.time)
    }
  },
  methods: {
    initFields() {
      const fields = []

      for (let i = 1; i <= this.countFields; i++) {
        fields.push(
          {
            id: i,
            value: '',
          }
        )
      }

      this.fields = fields
    },
    getField(id, forNextStep = true) {
      const nextId = forNextStep ? id + 1 : id - 1
      const nextField = this.$refs[`field-${nextId}`]
      return nextField && nextField[0] ? nextField[0] : null
    },
    isValidInputValue(e) {
      const expectedValue = e.data ? e.target.value + e.data : e.target.value

      return !expectedValue.match(this.reg)
    },
    onBeforeinput(e) {
      if (!this.isValidInputValue(e)) {
        e.preventDefault()
      }
    },
    onKeydown(e, field) {
      const expectedValue = e.target.value + e.key
      if (
        (!this.isValidKeys.includes(e.key) || expectedValue.length > 1)
        && (e.code !== 'Backspace' && e.key !== 'Backspace')
      ) {
        e.preventDefault()
      }

      // Фокус на след инпут для ввода
      if (
        this.isValidKeys.includes(e.key)
        && this.isValidKeys.includes(e.target.value)
        && field.id !== this.fields.length
      ) {
        const input = this.getField(field.id)
        input.focus()
        if (!input.value) {
          this.fields[field.id].value = e.key
        }
      }

      // Фокус на пред инпут для ввода
      if (
        (e.key === 'Backspace' || e.code === 'Backspace')
        && !e.target.value
        && field.id > 1
      ) {
        e.preventDefault()
        const input = this.getField(field.id, false)
        input.focus()
      }

    },
    onInput(e, field) {
      if (!this.isValidInputValue(e)) {
        e.target.value = e.target.value.replace(this.reg, '')
      }

      // Фокус на след инпут для ввода
      if (e.target.value && field.id !== this.fields.length) {
        const input = this.getField(field.id)
        input.focus()
      }

      // Фокус на пред инпут для ввода
      if (e.inputType === 'deleteContentBackward' && field.id > 1) {
        const input = this.getField(field.id, false)
        input.focus()
      }
    },
    isRequiredErrorField(index) {
      return this.$v.fields.$each[index].$error && !this.$v.fields.$each[index].required
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return
      }
      this.btnStatus = 'loading'

      const data = this.getFormData

      const options = {
        url: this.actionVerifyCode,
        method: 'POST',
        data
      }

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.$emit('success')
      } else {
        this.errorsAfterSubmit = response.data.errors
      }

      this.btnStatus = ''
    },
    async updateCode() {
      this.resetCode()
      this.$v.$reset()
      this.clearTimeToRefresh()

      this.btnStatus = 'loading'

      const data = new FormData()
      data.append('phone', this.phone)

      const options = {
        url: this.actionRefreshCode,
        method: 'POST',
        data
      }

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.startTimeToRefresh()
      } else {
        this.time = 0
        this.errorsAfterSubmit = response.data.errors
      }

      this.btnStatus = ''
    },
    resetCode() {
      for (let i in this.fields) {
        this.fields[i].value = ''
      }
    },
    startTimeToRefresh(seconds = 60) {
      this.clearTimeToRefresh()

      this.time = seconds

      this.timeIntervalId = setInterval(() => {
        this.time--
        if (this.time <= 0) {
          this.clearTimeToRefresh()
        }
      }, 1000)
    },
    clearTimeToRefresh() {
      this.time = 0
      clearInterval(this.timeIntervalId)
    }
  },
  created() {
    this.initFields()
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-contestants-verify';
$gap: 8px;
$size: calc((100% - ($gap * (var(--colums) - 1))) / var(--colums));

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;

  // .talisman-contestants-verify__caption
  &__caption {
    margin-bottom: 16px;
  }

  // .talisman-contestants-verify__body
  &__body {
    display: grid;
    grid-template-columns: repeat(var(--colums), $size);
    align-items: center;
    gap: $gap;
  }

  // .talisman-contestants-verify__input
  &__input {
    height: 80px;
    font-family: $font-family-fugue;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid $color-base-origin;
    border-radius: 16px;
    transition: $transtion-default;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // .talisman-contestants-verify__input--is-error
    &--is-error {
      border-color: $color-error;;
    }

    @include mobile {
      height: auto;
      width: 100%;
      aspect-ratio: 1/1;
    }
  }

  // .talisman-contestants-verify__actions
  &__actions {
    margin-top: 32px;
  }

  // .talisman-contestants-verify__btn
  &__btn {
    @include mobile {
      width: 100%;
    }
  }

  // .talisman-contestants-verify__feedback-error
  &__feedback-error {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 32px;
    }
  }

  // .talisman-contestants-verify__update
  &__update {
    margin-top: 32px;

    // .talisman-contestants-verify__update-btn
    &-btn {
      color: $color-base-origin;
      cursor: pointer;
    }
  }
}
</style>
