var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"},{name:"body-scroll-lock",rawName:"v-body-scroll-lock:reserveScrollBarGap",value:(_vm.isShow),expression:"isShow",arg:"reserveScrollBarGap"}],ref:"modal",staticClass:"v-modal",class:{
      'is-open': _vm.isShow,
      'v-modal--full-width-mob': _vm.isFullWidthModalComponent,
      'v-modal--size-xl': _vm.isXlWidthComponent,
      'v-modal--size-ll': _vm.isLlWidthComponent,
      'v-modal--size-lg': _vm.isLgWidthComponent,
    },on:{"mousedown":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeModal.apply(null, arguments)}}},[(_vm.dialogVisible)?_c('div',{staticClass:"v-modal__dialog",class:{
        'v-modal__dialog--hidden': !_vm.dialogVisible,
        'v-modal__dialog--full': _vm.isFullWidthComponent,
        'v-modal__dialog--size-lg': _vm.isLgWidthComponent,
      },attrs:{"tabindex":"0"}},[(_vm.redirectUrl)?_c('input',{ref:"redirectUrl",attrs:{"type":"hidden"},domProps:{"value":_vm.redirectUrl}}):_vm._e(),_c('div',{staticClass:"v-modal__close",on:{"click":_vm.closeModal}},[_c('inline-svg',{attrs:{"src":"close-thin"}})],1),(_vm.title || _vm.contentComponent.title)?_c('div',{staticClass:"v-modal__title tt-uppercase",domProps:{"innerHTML":_vm._s(_vm.title || _vm.contentComponent.title)}}):_vm._e(),(_vm.isShow && !_vm.contentComponent.name)?[_vm._t("default")]:[_c(_vm.contentComponent.name,_vm._b({tag:"component"},'component',_vm.contentComponent.props,false))]],2):_c('div',{staticClass:"v-modal__spinner"},[_c('div',{staticClass:"v-modal__spinner-inner"},[_c('inline-svg',{attrs:{"src":"spinner"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }