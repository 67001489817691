<template>
  <div
    class="u-calendar"
    :class="{ 'u-calendar--detail': isDetail, 'u-calendar--into-banner': intoBanner }"
  >
    <div class="u-calendar-inner">
      <div class="u-calendar__head">
        <div v-if="isVisibleTitle" class="u-calendar__head-top">
          <div class="u-calendar__head-info">
            <div class="u-calendar__head-title">
              Ни дня<br>без науки
            </div>
          </div>
        </div>
        <div class="u-calendar__head-bottom">
          <div class="u-calendar-controls">
            <div class="u-calendar-controls__prev" @click="prevMonth">
              <inline-svg class="u-calendar-controls__icon" src="u-arrow-left"/>
            </div>
            <div
              class="u-calendar-controls__menu"
              :class="{'u-calendar-controls__menu--is-opened': isMonthMenuOpened}"
            >
              <div
                v-if="!isDetail"
                class="u-calendar-controls__count"
                @click="onToggleMonthMenu"
              >
                <span class="u-calendar-controls__month">{{ getFormatDate(filterDate, "MMMM") }}</span>&nbsp;
                <span>{{ getFormatDate(filterDate, "YYYY") }}</span>
                <inline-svg class="u-calendar-controls__icon-dropdown" src="u-arr-down"/>
              </div>
              <div
                class="u-calendar-controls__dropdown"
                :class="{'u-calendar-controls__dropdown--is-hidden': !isMonthMenuOpened}"
              >
                <div
                  v-for="(item,i) in monthsList"
                  :key="`u-calendar-controls__dropdown-item-${i}`"
                  class="u-calendar-controls__dropdown-item"
                  @click="onSelectMonth(i)"
                >
                  {{item}}
                </div>
              </div>
            </div>
            <div class="u-calendar-controls__next" @click="nextMonth">
              <inline-svg class="u-calendar-controls__icon" src="u-arrow-right"/>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!breakpoint.isMobile" class="u-calendar__wrapper" >
        <div
          class="u-calendar-weekdays"
          v-if="intoBanner"
        >
          <div
            class="u-calendar-weekdays__day"
            v-for="(item,i) in weekDays"
            :key="`u-calendar__weekday-${i}`"
          >
            {{item}}
          </div>
        </div>
        <div
          class="u-calendar-days"
          v-for="(item, i) in gridArray"
          :key="i"
        >
          <div
            v-for="(date, i) in item"
            :key="i"
            class="u-calendar-days__day"
            :class="{
              'is-selected': isActiveDay(date),
              'is-event': isEventDay(date),
            }"
            @click="setDate(date)"
          >
            <span>{{ date.getDate() }}</span>
          </div>
        </div>
      </div>
      <swiper v-show="breakpoint.isMobile" ref="calendarSwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, i) in gridAFullArray" :key="i">
          <div
            class="u-calendar-days__day"
            :class="{
              'is-selected': isActiveDay(item),
              'is-event': isEventDay(item)
            }"
            @click="setDate(item)"
          >
            <span class="u-calendar-days__day-number">{{ item.getDate() }}</span>
            <span class="u-calendar-days__day-name">{{ getDayweek(item )}}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  inject: ['breakpoint'],
  name: "UCalendar",
  props: {
    subtitle: {
      type: String,
      default: ""
    },
    propDay: {
      type: Date,
      default: ""
    },
    eventDays: {
      type: Array
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isWaitChange: {
      type: Boolean,
      default: false
    },
    intoBanner: {
      type: Boolean,
      default: false
    },
    isVisibleTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedDay: undefined,
      filterDate: new Date(),
      locale: "ru",
      currentDay: null,
      weekDays: [
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб',
        'Вс',
      ],
      monthsList: [
        'Янв',
        'Фев',
        'Мар',
        'Апр',
        'Май',
        'Июн',
        'Июл',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек',
      ],
      isMonthMenuOpened: false
    };
  },
  watch: {
    eventDays() {
      if (this.isWaitChange) {
        this.isShow = true;
      }
    }
  },
  mounted() {
    this.selectedDay = this.propDay;
    this.setDate(this.selectedDay);
    this.currentDate();
    this.swiper.slideTo(this.currentDay - 1);
    document.addEventListener('click', this.onCloseFromClickOutside)
  },
  methods: {
    currentDate() {
      this.currentDay = new Date(this.selectedDay).getUTCDate();
    },
    prevMonth() {
      const tmpDate = this.filterDate;
      let tmpMonth = tmpDate.getMonth() - 1;
      let tmpYear = tmpDate.getFullYear();

      if (tmpMonth === -1) {
        tmpYear--;
      }

      this.filterDate = new Date(tmpDate.setMonth(tmpMonth));
      this.$emit('change-month', tmpDate.getMonth() + 1, tmpYear);
    },
    nextMonth() {
      const tmpDate = this.filterDate;
      let tmpMonth = tmpDate.getMonth() + 1;
      let tmpYear = tmpDate.getFullYear();

      if (tmpMonth === 12) {
        tmpYear++;
      }

      this.filterDate = new Date(tmpDate.setMonth(tmpMonth));
      this.$emit('change-month', tmpDate.getMonth() + 1, tmpYear);
    },
    setDate(date) {
      this.selectedDay = date;
      this.$emit("setdate", this.selectedDay);
    },
    isActiveDay(date) {
      return moment(date).isSame(this.selectedDay, 'day');
    },
    isEventDay(date) {
      return this.eventDays.some((element) => moment(element).isSame(moment(date), 'day'));
    },
    getCalendarMatrix(date) {
      const calendarMatrix = [];

      const startDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const startDow = (startDay.getDay() + 6) % 7;
      const endDow = (lastDay.getDay() + 6) % 7;

      startDay.setDate(startDay.getDate() - startDow);
      lastDay.setDate(lastDay.getDate() + (6 - endDow));

      let week = [];
      while (startDay <= lastDay) {
        week.push(new Date(startDay));
        if (week.length === 7) {
          calendarMatrix.push(week);
          week = [];
        }

        startDay.setDate(startDay.getDate() + 1);
      }

      return calendarMatrix;
    },
    getCalendarMonth(date) {
      const calendarMatrix = [];

      const startDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      while (startDay <= lastDay) {
        calendarMatrix.push(new Date(startDay));

        startDay.setDate(startDay.getDate() + 1);
      }
      return calendarMatrix;
    },
    getFormatDate(date, format) {
      return date
        ? moment(date)
            .locale(this.locale)
            .format(format)
        : "";
    },
    getDayweek(date) {
      return moment(date).format("ddd")
    },
    onToggleMonthMenu() {
      this.isMonthMenuOpened = !this.isMonthMenuOpened
    },
    onSelectMonth(i) {
      const tmpDate = this.filterDate;
      const tmpYear = tmpDate.getFullYear();

      this.filterDate = new Date(tmpDate.setMonth(i));
      this.$emit('change-month', i + 1, tmpYear);
      this.onToggleMonthMenu()
    },
    onCloseFromClickOutside(e) {
      if (!(e.target.classList.contains('u-calendar-controls__count') ||
        e.target.closest('.u-calendar-controls__count') ||
        e.target.closest('.u-calendar-controls__dropdown'))
      ) {
        this.isMonthMenuOpened = false
      }
    }
  },
  computed: {
    gridArray() {
      return this.getCalendarMatrix(this.filterDate);
    },
    gridAFullArray() {
      return this.getCalendarMonth(this.filterDate);
    },
    swiper() {
      return this.$refs.calendarSwiper.$swiper;
    },
    swiperOptions() {
      return {
        slidesPerView: 'auto',
        spaceBetween: 16,
        lazy: {
          loadPrevNext: true,
        },
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onCloseFromClickOutside)
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".u-calendar";

#{$b} {
  display: block;

  &__wrapper {
    // height: 340px;
    display: flex;
    flex-wrap: wrap;
    width: min-content;

    @include mobile {
      overflow-x: auto;
      height: auto;
      width: auto;
    }

    #{$b}--into-banner & {
      width: 100%;
      height: auto;
    }
  }

  &--detail {
    min-width: 442px;

    @include desktop {
      min-width: auto;
    }
  }

  &--into-banner{
    @include tablet-min {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    margin-bottom: 7.5px;

    @include mobile {
      margin-bottom: 1.5rem;
    }

    #{$b}--detail & {
      align-items: stretch;
    }

    #{$b}--into-banner & {
      @include mobile-min {
        margin-bottom: 27px;
      }
    }

    &-top {
      margin-bottom: 0.75rem;

      @include mobile {
        margin-bottom: 0;
      }
    }

    &-info {
      #{$b}--detail & {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &:after {
          content: "";
          width: 116px;
          height: 1.5px;
          position: absolute;
          top: 0.5em;
          left: #{-$offset-wrapper};
          background-color: $color-blue-line;
          background: linear-gradient(0deg, $color-blue-line 0%, #4f92ff 100%);

          @include low-desktop {
            display: none;
          }
        }
      }
    }

    &-title {
      font-weight: 500;
      font-size: 36px;
      line-height: 100%;
      font-family: $font-family-fugue;
      text-transform: uppercase;

      @include mobile {
        font-size: 48px;
        line-height: 100%;
        margin-bottom: 24px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      #{$b}--detail & {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color-base;
        margin: 0;

        &:not(:last-child) {
          &::after {
            content: "/";
            display: inline-block;
            vertical-align: baseline;
            margin: 0 10px;
            color: $color-base;
          }
        }
      }
    }

    &-subtitle {
      min-height: 20px;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-base;

      #{$b}--detail & {
        color: inherit;
      }
    }
  }

  &-controls {
    display: flex;
    justify-content: space-between;

    #{$b}--detail & {
      margin-top: 0;
    }

    // .u-calendar-controls__icon
    &__icon {
      color: $black-true;
      stroke: $black-true;
      width: 24px;
      height: 16px;

      @include mobile {
        width: 100%;
      }
    }

    // .u-calendar-controls__icon-dropdown
    &__icon-dropdown {
      transition: $transtion-default;
      width: 13px;
      height: 13px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);

      #{$b}-controls__menu--is-opened & {
        transform: translateY(-50%) rotate(180deg);
      }

      svg {
        * {
          fill: transparent !important;
        }
      }

    }

    // .u-calendar-controls__menu
    &__menu {
      margin: 0 8px;
      width: 100%;
      height: auto;
      position: relative;
    }

    // .u-calendar-controls__dropdown
    &__dropdown {
      max-height: 200px;
      width: 100%;
      position: absolute;
      left: 0;
      top: calc(100% - 1px);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      justify-content: space-between;
      background-color: $color-secondary;
      padding: 5px 16px 16px 16px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      transition: $transtion-default;
      z-index: 3;
      overflow: hidden;

      @include mobile {
        background-color: $white-true;
      }

      &--is-hidden {
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        touch-action: none;
        transform: translateY(-20px);
      }

      // .u-calendar-controls__dropdown
      &-item {
        font-weight: 500;
        font-size: 16px;
        line-height: 180%;
        color: rgba($black-true, 0.5);
        height: 34px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: $transtion-default;
        cursor: pointer;
        border-radius: 8px;
        background-color: $white-true;

        @include mobile {
          font-size: 14px;
          background-color: $color-secondary;
        }

        @include mobile-sm {
          font-size: 12px;
        }

        &:hover {
          color: $black-true;
        }

        &:nth-child(3n + 1) {
          text-align: left;
        }
        &:nth-child(3n + 2) {
          text-align: center;
        }
        &:nth-child(3n + 3) {
          text-align: right;
        }
      }
    }

    &__count {
      height: 100%;
      font-weight: 500;
      font-size: 1rem;
      line-height: 120%;
      white-space: nowrap;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 20px;
      color: $black-true;
      transition: $transtion-default;
      cursor: pointer;
      background-color: $color-secondary;
      position: relative;
      padding-left: 16px;

      @include mobile {
        background-color: $white-true;
        border-color: $white-true;
        justify-content: center;
        text-align: center;
        padding-left: 0;
      }

      #{$b}-controls__menu--is-opened & {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-color: $white-true;
      }

      & > * {
        &:first-child {
          color: $color-black;
        }
      }
    }

    &__month {
      text-transform: capitalize;
    }

    &__prev,
    &__next {
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $color-secondary;
      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: center;
      outline: 0;
      text-decoration: none;
      user-select: none;
      cursor: pointer;
      flex-shrink: 0;
      transition: $transtion-default;
      color: inherit;

      svg {

        path {
          fill: transparent !important;
        }
      }

      @include mobile {
        width: 38px;
        min-width: 38px;
        max-width: 38px;
        height: 38px;
        background-color: $white-true;
      }
    }

    &__prev {
      .u-calendar-controls__icon {
        margin-right: 2px;
      }
    }
    &__next {
      .u-calendar-controls__icon {
        margin-left: 2px;
      }
    }
  }

  &-weekdays,
  &-week,
  &-days {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      margin-bottom: 2rem !important;
    }

    &:not(:last-child) {
      margin-bottom: 8px;

      @include desktop {
        margin-bottom: 4px;
      }

      #{$b}--detail & {
        margin-bottom: 0;
      }
    }

    #{$b}--into-banner & {
      width: 100%;
    }

    &__day {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: $color-black;

      #{$b}--into-banner & {
        @include mobile-min {
          font-family: $font-family-inter;
          font-weight: 400;
          font-size: 24px;
          line-height: 150%;
        }
      }

      span {
        position: relative;
        z-index: 2;
      }

      @include desktop {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }

      #{$b}--detail & {
        color: $white-true;
      }


    }
  }

  &-weekdays {
    &__day {
      font-weight: 700 !important;
      color: $white-true;
      &:nth-child(6),
      &:nth-child(7) {
        color: $color-base-origin;
      }

      min-width: 64px;
      max-width: 64px;
      height: 36px;
    }
  }

  &-week {
    &__day {
      &.is-weekend {
        color: $color-base;
      }
    }
  }

  &-days {
    &__day {
      cursor: default;
      pointer-events: none;
      color: $color-gray;
      transition: 0.2s ease-out;
      transition-property: color, background-color;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 1px solid transparent;
      transition: $transtion-default;

      @include mobile {
        width: 100%;
        height: 60px;
        flex-direction: column;
        box-shadow: 0 4px 12px rgba(11, 32, 95, 0.1);
        border-radius: 16px;
        background-color: #ffffff;
        margin-left: 0;
      }

      #{$b}--detail & {
        color: #6387EC;
      }

      #{$b}--into-banner & {
        @include mobile-min{
          color: rgba($white-true, 0.5);
          min-width: 64px;
          max-width: 64px;
          height: 64px;
        }

        @include mobile {
           &:not(.is-event){
            color: $black-true;
            background-color: rgba($white-true, 0.5);
          }
        }
      }

      &.is-event {
        cursor: pointer;
        pointer-events: all;
        position: relative;
        color: #000000;

        #{$b}--detail & {
          color: $white-true;
        }

        #{$b}--into-banner & {
          &:not(.is-selected) {
            @include mobile-min {
              color: $white-true;
            }
          }
        }

        &:hover,
        &:focus {
          &:not(.is-selected) {
            color: $color-base;
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          margin: 10px auto 0;
          background-color: $color-base;
          width: 3px;
          height: 3px;
          border-radius: 3px;

          @include mobile {
            display: none;
          }

          #{$b}--into-banner & {
            background-color: $white-true;
            top: 48px;
            left: 0;
            transform: none;
            margin: 0 auto 0;
          }
        }
      }

      &.is-selected {
        color: $white-true;
        background-color: $color-base;

        &:after {
          background-color: $white-true;
        }

        #{$b}--detail & {
          background-color: $white-true;
          color: $color-base;
        }

        #{$b}--into-banner & {
          @include mobile-min {
            background-color: $white-true;
            color: $color-base;

            &:after{
              background-color: $color-base-origin;
            }
          }
        }
      }
    }

    // .u-calendar-days__day-number ,
    // .u-calendar-days__day-name
    &__day-number,
    &__day-name {
      @include mobile {
        display:block;
      }
    }
  }

  &:not(#{$b}--into-banner) {
    .swiper {
      &-slide {
        @include mobile {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .swiper {
    &-wrapper {
      @include mobile {
        // margin-left: 36px;
      }
    }

    &-container {
      @include mobile {
        margin: 0 -12px;
      }
    }

    &-slide {
      @include mobile {
        display: flex;
        width: 50px;
        margin-bottom: 1.5rem;
      }
    }
  }
}
</style>
