<template>
  <button class="back-link tt-uppercase" @click="goBack">
    <inline-svg src="arr-left"></inline-svg>
    <span><slot>Назад</slot></span>
  </button>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack() {
      if (document.referrer) {
        window.history.go(-1)
      } else {
        window.location.href = this.href
      }
    }
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.back-link';

#{$b} {
  display: inline-flex;
  width: max-content;
  align-items: center;
  color: $white-true;
  font-family: $font-family-inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.1em;
  text-decoration: none;
  margin-bottom: 32px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;

  .inline-svg {
    svg {
      fill: unset !important;
      & > * {
        fill: unset !important;
      }
    }
  }

  &:hover {
    .inline-svg {
      transform: translateX(-10px);
    }
  }

  &--equal-title {
    top: 5px;
  }

  .section--search-page & {
    top: auto;
    bottom: 0;
  }

  .inline-svg {
    display: inline-block;
    vertical-align: middle;
    width: 29px;
    height: 16px;
    margin-right: 8px;
    transition: .2s;

    svg {
      fill: transparent !important;
      & > * {
        fill: transparent !important;
      }
    }
  }

  span {
    margin-bottom: -1px;
    display: inline-block;
    vertical-align: middle;
    transition: .2s;
  }
}
</style>
