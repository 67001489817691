<template>
  <div class="talisman-work-window">
    <div class="talisman-work-window__header">
      <h3 class="talisman-work-window__header-title tt-uppercase title-xl" v-html="getTitle" />
    </div>
    <TalismanWorkForm
      v-show="step === 1"
      :actionSubmit="actionSubmit"
      :categoryOptions="categoryOptions"
      :regionOptions="regionOptions"
      @submit="submitFormSuccess"
    />
    <TalismanWorkCaptcha
      v-if="step === 2"
      :siteKey="siteKey"
      @submit="submitFormVerify"
    />
    <TalismanWorkSuccess
      v-if="step === 3"
      @close="closeWindow"
    />
    <template v-if="step === 'errorSubmit'">
      <ErrorFormWrapper
        class="talisman-work-window__feedback-errors"
        :max-height="1000"
        :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
      >
        <div
          v-for="error in errorsAfterSubmit"
          :key="`talisman-work-window__feedback-error-${error.code}`"
          class="talisman-work-window__feedback-error"
        >
          <span v-html="error.message" />
        </div>
      </ErrorFormWrapper>
      <CustomButton
        class="talisman-work-window__try"
        theme="primary"
        size="md"
        @click="step = 1"
      >
        Вернуться
      </CustomButton>
    </template>
    <div
      class="talisman-work-window__loader"
      :class="{'talisman-work-window__loader--is-visible': isLoading}"
    >
      <inline-svg
        class="talisman-work-window__loader-icon"
        src="loading"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    actionSubmit: {
      type: String,
      default: ''
    },
    categoryOptions: {
      type: Array,
      default: () => []
    },
    regionOptions: {
      type: Array,
      default: () => []
    },
    siteKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      step: 1,
      titles: {
        1: 'Заполните форму',
        2: 'верификация',
        3: 'Спасибо за&nbsp;участие!',
        errorSubmit: 'Ошибка'
      },
      formData: null,
      errorsAfterSubmit: [],
      isLoading: false,
    }
  },
  computed: {
    getTitle() {
      return this.titles[this.step]
    }
  },
  methods: {
    submitFormSuccess(formData) {
      this.formData = formData
      this.step = 2
    },
    closeWindow() {
      this.$root.$refs.customModal.closeModal();
    },
    submitFormVerify(token) {
      this.formData.append('token', token)
      this.onSubmit()
    },
    async onSubmit() {
      this.isLoading = true
      const options = {
        url: this.actionSubmit,
        method: 'POST',
        data: this.formData
      };

      try {
        const response = await axios(options);

        if (response.data.status === 'success') {
          this.step = 3
        } else {
          this.errorsAfterSubmit = response.data.errors
          this.step = 'errorSubmit'
          this.formData = ''
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-work-window';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;

  // .talisman-work-window__header
  &__header {
    margin-bottom: 32px;
    margin-right: 24px;

    // .talisman-work-window__header-title
    &-title {
      font-family: $font-family-fugue;
      margin: 0;
    }
  }

   // .talisman-work-window__feedback-error
  &__feedback-error {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }

  // .talisman-work-window__try
  &__try {
    @include mobile {
      width: 100%;
    }
  }

  &__loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-base-origin;
    background-color: rgba($white-true, 0.3);
    transition: opacity 0.2s ease;

    &--is-visible {
      opacity: 1;
    }

    &:not(#{$b}__loader--is-visible) {
      opacity: 0;
      pointer-events: none;
      touch-action: none;
    }

    &-icon {
      width: 30px;
      height: 30px;
      max-width: 30px;
      max-height: 30px;
      *  {
        fill: transparent !important;
        stroke: currentColor;
      }
    }
  }
}
</style>
