<template>
  <u-figures
    :items="[
      {'name':'f-lk-organizations-1','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
    ]"
    :full-width="true"
    :is-overflow="true"
    position="static"
  >
    <div class="u-lk-organizations">
      <!-- TODO: временно скрываем блок -->
      <!-- <div class="u-lk-organizations__header">
        <h2 class="u-lk-organizations__title tt-uppercase" v-html="head.title">
        </h2>
        <p v-if="Boolean(head.text)" class="u-lk-organizations__text" v-html="head.text"></p>
        <p v-if="Boolean(head.subtext)" class="u-lk-organizations__subtext" v-html="head.subtext"></p>
      </div>
      <div class="u-lk-organizations__list">
        <PrimaryList
          v-if="Boolean(tipsList.length)"
          :list="tipsList"
        />
      </div> -->
      <!-- TODO: временно скрываем блок с видео -->
     <!-- <div v-if="Boolean(videos.length)" class="u-lk-organizations__videos">
        <h4 class="u-lk-organizations__videos-title">
          Можешь воспользоваться нашими видео-роликами:
        </h4>
        <UBrandstyleVideoList
          class="u-lk-organizations__video-list"
          :pages="videoPages"
          :count="videoCount"
          :videos="videos"
          :url="videosFetchUrl"
        />
        <p class="u-lk-organizations__videos-text">
          Срок загрузки отчета - не позднее 5 дней с момента завершения мероприятия
        </p>
     </div> -->
      <slot name="information" />
    </div>
  </u-figures>
</template>

<script>
export default {
  props: {
    head: {
      type: Object,
      default: () => ({
        title: 'Помощь в организации',
        text: 'Поздравляем! Ваше мероприятие полностью соответствует целям и задачам Десятилетия науки и технологий. Теперь вы можете скачать <a href=\'/upload/PNG.zip\'>логотип Десятилетия науки и технологий</a> и брендировать свое мероприятие.',
        subtext: 'Также вы можете воспользоваться нашими советами по интеграции::'
      })
    },
    tipsList: {
      type: Array,
      default: () => [
        {
          text: 'Специальные экспертные сессии, <br> панельные дискуссии, привязанные к тематике месяца Года.',
          additionalLogo: '',
          size: '',
        },
        {
          text: 'Интеграция спикеров из числа молодых ученых в программу мероприятия.',
          additionalLogo: '',
          size: '',
        },
        {
          text: 'В рамках конкурсов и/или премий могут быть интегрированы номинации по научной тематике.',
          additionalLogo: '',
          size: '',
        },
        {
          text: 'Специальные показы цикла <br> документального сериала «Наука. Территория героев» о достижениях <br> российских молодых ученых.',
          additionalLogo: '',
          size: '',
        },
        {
          text: 'Показ промо материалов <br> Года (презентационные ролики о&nbsp;Годе, о&nbsp;научных достижениях и разработках, о&nbsp;молодых ученых).',
          additionalLogo: '',
          size: '',
        },
        {
          text: 'Брендирование мероприятия (стендов, печатной продукции, сайт) логотипом Года науки и технологий.',
          additionalLogo: '',
          size: '',
        },
        {
          text: 'Включение информации о Годе науки и технологий в раздаточные материалы, печатную продукцию, на сайте <br> мероприятия, соц. медиа, в анонсах и пресс-релизах.',
          additionalLogo: '',
          size: '',
        },
      ]
    },
    videos: {
      type: Array,
      default: () => []
    },
    videoPages: {
      type: [String, Number],
      require: true
    },
    videoCount: {
      type: [String, Number],
      require: true
    },
    videosFetchUrl: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-lk-organizations';

#{$b} {
  position: relative;
  font-family: $font-family-inter;
  color: $black-true;

  // .u-lk-organizations__header
  &__header {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .u-lk-organizations__title
  &__title {
    font-family: $font-family-fugue;
    font-size: 44px;
    line-height: 1;
    font-weight: 500;
    margin: 0;

    @include mobile {
      font-size: 28px;
    }
  }

  // .u-lk-organizations__text
  &__text {
    font-size: 24px;
    font-weight: normal;
    margin: 32px 0 0 0;

    @include mobile {
      font-size: 16px;
      margin-top: 24px;
    }

    a {
      color: $color-base;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // .u-lk-organizations__subtext
  &__subtext {
    margin: 32px 0 0 0;

    @include mobile {
      display: none;
    }
  }

  // .u-lk-organizations__list
  &__list {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 48px;
    }
  }

  // .u-lk-organizations__videos
  &__videos {

    // .u-lk-organizations__videos-title
    &-title {
      font-size: 24px;
      font-weight: normal;
      line-height: 1.5;
      margin: 0 0 32px 0;

      @include mobile {
        margin-bottom: 24px;
      }
    }

    // .u-lk-organizations__videos-text
    &-text {
      font-size: 16px;
      color: $color-info-secondary;
      margin: 32px 0 0 0;

      @include mobile {
        margin-top: 24px;
      }
    }
  }

  // .u-lk-organizations__contacts
  &__contacts {
    // TODO: вернуть вместе с блоком .u-lk-organizations__videos
    // margin-top: 32px;
    // padding-top: 32px;
    // border-top: 1px solid $color-info-secondary-2;


    @include mobile {
      // TODO: вернуть вместе с блоком .u-lk-organizations__videos
      // margin-top: 48px;
      // padding-top: 48px;
    }
  }

  // .u-lk-organizations__other-info
  &__other-info {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.5;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    a {
      color: $color-base;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    @include mobile {
      font-size: 16px;
      line-height: 1.3;
      margin-top: 16px;
    }
  }
}
</style>
