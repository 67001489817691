import axios from "axios"

export default {
  namespaced: true,
  state: {
    items: [],
    isLoading: false,
    nextUrl: ''
  },

  getters: {
    ITEMS(state) {
      return state.items
    },
    IS_LOADING(state) {
      return state.isLoading
    },
    NEXT_URL(state) {
      return state.nextUrl
    }
  },

  mutations: {
    SET_ITEMS(state, items, withReplace = false) {
      state.items = withReplace ? items : [...state.items, ...items]
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_NEXT_URL(state, nextUrl) {
      state.nextUrl = nextUrl
    }
  },

  actions: {
    async LOAD_ITEMS({ commit, state }) {
      if (!state.nextUrl) {
        return
      }

      try {
        commit('SET_LOADING', true)
        const response = await axios(state.nextUrl)

        if ([200,201].includes(response?.status) && response.data) {
          commit('SET_ITEMS', response.data.ITEMS)
          commit('SET_NEXT_URL', response.data.NEXT_URL)

          return {
            ITEMS: response.data.ITEMS,
            NEXT_URL: response.data.NEXT_URL
          }
        }
      } catch(e) {
        throw new Error(`initiativesEvent:LOAD_ITEMS | ${e}`)
      } finally {
        commit('SET_LOADING', false)
      }
    }
  }
}
