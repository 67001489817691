<template>
  <div class="u-brand-style-video">
    <div
      class="u-brand-style-video__header"
      @click="$emit('changeIndex')"
    >
      <img
        :src="data.cover"
        :alt="data.title"
        class="u-brand-style-video__preview"
      >
      <inline-svg
        class="u-brand-style-video__btn-play"
        src="u-play"
      ></inline-svg>
    </div>
    <div class="u-brand-style-video__body">
      <h3 class="u-brand-style-video__title tt-uppercase" v-html="data.title"></h3>
      <div class="u-brand-style-video__actions">
        <custom-button
          class="u-brand-style-video__btn-download"
          tag="a"
          :href="data.src"
          target="_blank"
          download
        >
          <inline-svg
            class="u-brand-style-video__download-icon"
            src="u-arr-download"
          ></inline-svg>
          Скачать
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      require: true
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-brand-style-video';

#{$b} {
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: $transtion-default;
  border-radius: 16px;
  overflow: hidden;
  background-color: $color-base;

  &:hover {
    @include mobile-min {
      transform: scale(1.02);
    }
  }

  @include mobile {
    min-height: auto;
  }

  // .u-brand-style-video__header
  &__header {
    position: relative;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;

    @include mobile {
      height: auto;
      aspect-ratio: 330/186;
    }

    &:hover {
      @include mobile-min {
        svg {
          color: $color-base-origin !important;
        }
      }
    }
  }

  // .u-brand-style-video__preview
  &__preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
    touch-action: none;
    opacity: 0.7;
    transition: $transtion-default;
  }

  // .u-brand-style-video__btn-play
  &__btn-play {
    width: 64px;
    height: 64px;
    position: relative;

    svg {
      color: $white-true;
      transition: $transtion-default;
    }
  }

  // .u-brand-style-video__body
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 34px 24px 24px 24px;
    color: $white-true;

    @include mobile {
      padding: 26px 20px 24px 20px;
    }
  }

  // .u-brand-style-video__title
  &__title {
    font-family: $font-family-fugue;
    font-weight: 500;
    font-size: 28px;
    line-height: 100%;
    margin: 0 0 24px 0;
  }

  // .u-brand-style-video__download-icon
  &__download-icon {
    margin-right: 8px;
    width: 16px !important;

    svg {
      fill: transparent !important;
      & > * {
        fill: transparent !important;
        stroke: currentColor;
      }
    }
  }
}
</style>
