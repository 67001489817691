var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.MAIN_ITEMS?.length)?_c('div',{staticClass:"header-menu",class:[
    { 'header-menu--submenu-opened': _vm.isOpened, },
    `header-menu--mode-${_vm.mode}`
  ],style:(`--animation-time: ${_vm.animationTime}ms`),on:{"pointerleave":_vm.onBlurRoot}},[_c('div',{staticClass:"header-menu__inner"},[_c('div',{staticClass:"header-menu__list"},_vm._l((_vm.MAIN_ITEMS),function(item){return _c('div',{key:`header-menu__item-${item.id}`,staticClass:"header-menu__item header-menu__item--main",class:{ 'header-menu__item--has-childs': item.childs?.length }},[_c(_vm.linkTag,{tag:"component",staticClass:"header-menu__link",class:{
            'header-menu__link--focused': _vm.isFocusedBtn(item) || _vm.isVisibleMobileSubmenu(item.id),
            'header-menu__link--has-childs': item.childs?.length
          },attrs:{"tag":"a","size":_vm.isDefaultMode ? 'sm' : '',"theme":_vm.isDefaultMode ? _vm.getBtnTheme(item) : '',"no-hover":_vm.isDefaultMode ? _vm.isFocusedBtn(item) : '',"href":item.url,"target":item.target},on:{"pointerenter":function($event){return _vm.onHover(item)},"click":(e) => _vm.onClick(e, item)}},[_c('span',{domProps:{"innerHTML":_vm._s(item.name)}}),(item.childs?.length > 0)?_c('span',{staticClass:"header-menu__link-icon"},[_c('InlineSvg',{attrs:{"src":"u-arr-down"}})],1):_vm._e()]),(item.childs?.length > 0 && _vm.breakpoint.isMobile)?_c('div',{staticClass:"header-menu__submenu",class:{ 'header-menu__submenu--is-hidden': !_vm.isVisibleMobileSubmenu(item.id) }},[_c('div',{ref:"submenuList",refInFor:true,staticClass:"header-menu__submenu-list header-menu__list"},_vm._l((item.childs),function(item){return _c('div',{key:`header-menu__submenu-item-${item.id}`,staticClass:"header-menu__submenu-item header-menu__item"},[_c(_vm.linkTag,{tag:"component",staticClass:"header-menu__submenu-link header-menu__link",attrs:{"tag":"a","size":_vm.isDefaultMode ? 'sm' : '',"theme":_vm.isDefaultMode ? 'outline-light-dark' : '',"no-hover":_vm.isDefaultMode ? item.isActive : false,"href":item.url,"target":item.target}},[_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})])],1)}),0)]):_vm._e()],1)}),0)]),(_vm.submenu && !_vm.breakpoint.isMobile)?_c('div',{staticClass:"header-menu__submenu",class:{
      'header-menu__submenu--is-hidden': !_vm.isOpened,
    }},[_c('div',{staticClass:"header-menu__submenu-list header-menu__list",class:{ 'header-menu__submenu-list--align-right': _vm.focusedItem?.alignRight }},_vm._l((_vm.submenu),function(item){return _c('div',{key:`header-menu__submenu-item-${item.id}`,staticClass:"header-menu__submenu-item header-menu__item"},[_c(_vm.linkTag,{tag:"component",staticClass:"header-menu__submenu-link header-menu__link",attrs:{"tag":"a","size":_vm.isDefaultMode ? 'sm' : '',"theme":_vm.isDefaultMode ? (item.isActive ? 'light-black' : 'outline-light-dark') : '',"no-hover":_vm.isDefaultMode ? item.isActive : false,"href":item.url,"target":item.target}},[_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})])],1)}),0)]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }