<template>
  <article v-if="isExistsExpert" class="talisman-expert">
    <div class="talisman-expert__preview">
      <img :src="expert.img" :alt="expert.name" class="talisman-expert__preview-img">
    </div>
    <div class="talisman-expert__body">
      <h4 class="talisman-expert__body-title tt-uppercase">
        {{ expert.name }}
      </h4>
      <p class="talisman-expert__body-text" v-html="expert.text" />
    </div>
  </article>
</template>

<script>
export default {
  props: {
    expert: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isExistsExpert() {
      return this.expert && Object.values(this.expert).length
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-expert';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;

  // .talisman-expert__preview
  &__preview {
    margin-bottom: 16px;

    // .talisman-expert__preview-img
    &-img {
      width: 100%;
      height: auto;
      aspect-ratio: 264/320;
      object-fit: cover;
      border-radius: 500px;

      @include mobile {
        aspect-ratio: 290/320;
      }
    }
  }

  // .talisman-expert__body
  &__body {

    // .talisman-expert__body-title
    &-title {
      font-family: $font-family-fugue;
      word-spacing: 100vw;
      font-size: 20px;
      line-height: 1;
      font-weight: 500;
      color: $color-base;
      margin: 0 0 16px 0;
    }

    // .talisman-expert__body-text
    &-text {
      margin: 0;
    }
  }
}
</style>
