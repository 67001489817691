<template>
  <section class="talisman-experts">
    <div class="u-g-container">
      <div class="talisman-experts__inner" ref="expertsInner">
        <div class="talisman-experts__header">
          <h2 class="talisman-experts__title title-xl tt-uppercase">
            {{ title }}
          </h2>
        </div>
        <div class="talisman-experts__body">
          <template v-if="breakpoint.isMobile">
            <swiper
              class="talisman-experts__swiper"
              :options="swiperOptions"
            >
              <swiper-slide
                v-for="expert in expertList"
                :key="`talisman-experts__swiper-slide-${expert.id}`"
                class="talisman-experts__swiper-slide"
              >
                <TalismanExpert :expert="expert" />
              </swiper-slide>
            </swiper>
            <div class="talisman-experts__mob-pagination">
              <div class="u-swiper-pagination" :class="`talisman-experts__pagination-${_uid}`"></div>
            </div>
          </template>
          <template v-else>
            <div
              ref="experstList"
              class="talisman-experts__list"
              :style="expertsListStyle"
              :class="{'talisman-experts__list--is-opened': isOpened}"
            >
              <TalismanExpert
                :ref="`expert_${i+1}`"
                v-for="(expert, i) in expertList"
                :key="`talisman-experts__list-item-${expert.id}`"
                :expert="expert"
                class="talisman-experts__list-item"
              />
            </div>
            <div v-if="isCanToggleList" class="talisman-experts__toggle">
              <CustomButton
                class="talisman-experts__toggle-btn"
                theme="primary"
                size="md"
                @click="toggleList"
              >
                {{ getBtnToggleText }}
              </CustomButton>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  inject: ['breakpoint'],
  props: {
    title: {
      type: String,
      default: ''
    },
    expertList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isOpened: false,
      expertsListStyle: '',
      isCanToggleList: true,
      windowWidth: null,
    }
  },
  computed: {
    swiperOptions() {
      return {
        slidesPerView: 1.17,
        spaceBetween: 16,
        pagination: {
          el: `.talisman-experts__pagination-${this._uid}`,
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
      }
    },
    getBtnToggleText() {
      return this.isOpened ? 'Свернуть' : 'Показать всех'
    }
  },
  methods: {
    toggleList() {
      this.isOpened = !this.isOpened

      if (!this.isOpened) {
        this.scrollToTopBlock()
      }
    },
    checkHeightList() {
      if (!this.windowWidth || this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth

        this.expertsListStyle = 'transition: unset;'
        this.$nextTick(() => {
          setTimeout(() => {
            const refList = this.$refs.experstList
            const refListFirstChild = this.$refs[`expert_${1}`]

            if (this.breakpoint.isMobile || !refList || !refListFirstChild) {
              return
            }

            const { height } = refList.getBoundingClientRect()
            const { height: maxHeight } = refListFirstChild[0].$el.getBoundingClientRect()

            if (height === maxHeight) {
              this.expertsListStyle = ''
              this.isCanToggleList = false
            } else {
              this.expertsListStyle = `--fullHeight: ${Math.round(height + (height * 0.15))}px; --limitHeight: ${Math.round(maxHeight)}px`
              this.isCanToggleList = true
            }
          });
        })
      }
    },
    scrollToTopBlock() {
      this.$refs.expertsInner.scrollIntoView()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.checkHeightList()
    })
    window.addEventListener('resize', this.checkHeightList)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkHeightList)
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-experts';

#{$b} {

  // .talisman-experts__header
  &__header {
    margin-bottom: 40px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .talisman-experts__title
  &__title {
    font-family: $font-family-fugue;
    margin: 0;
  }

  // .talisman-experts__body
  &__body {
    position: relative;
  }

  .swiper-container {
    overflow: unset;
  }

  // .talisman-experts__mob-pagination
  &__mob-pagination {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  // .talisman-experts__list
  &__list {
    display: grid;
    grid-template-columns: repeat(4, calc((100% - (44px * 3)) / 4));
    gap: 40px 44px;
    transition: all 0.3s ease;
    overflow: hidden;
    max-height: var(--limitHeight);

    @include low-desktop {
      grid-template-columns: repeat(3, calc((100% - (24px * 2)) / 3));
      gap: 24px;
    }

    // .talisman-experts__list--is-opened
    &--is-opened {
      max-height: var(--fullHeight);
    }
  }

  // .talisman-experts__toggle
  &__toggle {
    margin-top: 32px;
  }
}
</style>
