<template>
  <div class="u-social">
    <div class="u-social__inner">
      <a :href="VKSharingUrl" target="_blank" class="u-social__link">
        <InlineSvg class="u-social__link-svg" src="u-social/vk" />
      </a>
      <a :href="TGSharingUrl" target="_blank" class="u-social__link">
        <InlineSvg class="u-social__link-svg" src="u-social/tg" />
      </a>
      <a :href="OKSharingUrl" target="_blank" class="u-social__link">
        <InlineSvg class="u-social__link-svg" src="u-social/ok" />
      </a>
      <button class="u-social__link u-social__link--share" @click="copyCurrentUrl">
        <InlineSvg class="u-social__link-svg" src="u-social/share" />
      </button>
    </div>
  </div>
</template>

<script>
import saveInBuffer from '/frontend/components/helpers/saveInBuffer.js'
import decodeCyrillicDomain from '/frontend/components/helpers/decodeCyrillicDomain.js'

export default {
  props: {
    sharingUrl: {
      type: String,
      default: ''
    },
    sharingTitle: {
      type: String,
      default: ''
    },
    sharingDescription: {
      type: String,
      default: ''
    },
    sharingImage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentUrl: this.sharingUrl,
    }
  },
  created() {
    if (!this.sharingUrl) {
      this.currentUrl = window.location.href
    }
  },
  computed: {
    VKSharingUrl() {
      return `https://vk.com/share.php?${this.assembleQueryString({url: this.formattedUrl, title: this.sharingTitle, image: this.sharingImage})}`;
    },
    TGSharingUrl() {
      return `https://t.me/share/url?${this.assembleQueryString({url: this.formattedUrl, text: this.sharingDescription || this.sharingTitle})}`;
    },
    OKSharingUrl() {
      return `https://connect.ok.ru/offer?${this.assembleQueryString({url: this.formattedUrl, title: this.sharingTitle, description: this.sharingDescription, image: this.sharingImage})}`;
    },
    formattedUrl() {
      return decodeCyrillicDomain(this.currentUrl)
    }
  },
  methods: {
    copyCurrentUrl() {
      saveInBuffer(this.formattedUrl)
    },
    assembleQueryString(data) {
      let list = [];
      for (let p in data) {
        if (data[p]) {
          list.push(`${p}=${data[p]}`);
        }
      }
      return list.join('&');
    },
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: ".u-social";
$item-size: 34px;

#{$b} {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 2px;
    width: 100%;
    height: 1px;
    background-color: $black-true;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    touch-action: none;
  }

  // .u-social__inner
  &__inner {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 8px;
    border-radius: 40px;
    background-color: $white-true;
    z-index: 1;
  }

  // .u-social__link
  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: $item-size;
    padding: 6px;
    width: $item-size;
    height: $item-size;
    border-radius: 50%;
    border: 1px solid $color-secondary-5;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    text-decoration: none;
    color: $black-true;
    transition: $transtion-default;

    // .u-social__link-svg
    &-svg {
      max-width: 100%;
      max-height: 100%;

      #{$b}__link--share & {
        path {
          fill: transparent !important;
          stroke: currentColor;
        }
      }
    }

    &:hover {
      color: $color-base;
      border-color: $color-base;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

</style>
