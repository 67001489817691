<template>
  <UFigures
    class="lk-wrapper__figures"
    :items="[
      { 'name':'f-lk-digest-circle','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left' },
    ]"
    fullWidth
    position="static"
  >
    <div class="u-lk-digest">
      <div class="u-lk-digest__header">
        <h2 class="u-lk-digest__header-title">
            Информация для дайджеста
        </h2>
        <p class="u-lk-digest__header-text">
          Дайджест – это подборка наиболее популярных, интересных информационных поводов, проведенных мероприятий, реализованных проектов в регионе за запрашиваемый период. Сводный отчет формируется АНО «Национальные приоритеты».
        </p>
      </div>
      <ULkDigestForm
        :initiativesOptions="initiativesOptions"
        :submitUrl="actionAddDigest"
      />
      <ULkDigestTable
        v-if="Boolean(items) && Boolean(items.length)"
        class="u-lk-digest__table"
        :initItems="items"
        :actionEditDigest="actionEditDigest"
        :actionRemoveDigest="actionRemoveDigest"
        :actionGetDigest="actionGetDigest"
      />
      <NotFound
        v-else
        class="u-lk-digest__not-found"
        text='У вас пока что нет предложенной информации для дайджеста. Пожалуйста, заполните форму выше и нажмите кнопку "Отправить".'
      />
    </div>
  </UFigures>
</template>

<script>
export default {
  props: {
    initiativesOptions: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    actionAddDigest: { type: String, default: '' },
    actionEditDigest: { type: String, default: '' },
    actionRemoveDigest: { type: String, default: '' },
    actionGetDigest: { type: String, default: '' }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-lk-digest';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;
  position: relative;

  // .u-lk-digest__header
  &__header {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 32px;
    }

    // .u-lk-digest__header-title
    &-title {
      margin: 0 0 25px 0;
      text-transform: uppercase;
      font-family: $font-family-fugue;
      font-size: 44px;
      font-weight: 500;
      line-height: 100%;
      color: $black-true;

      @include mobile {
        font-size: 28px;
        margin-bottom: 16px;
      }
    }
    // .u-lk-digest__header-text
    &-text {
      margin: 0;
    }
  }

  // .u-lk-digest__body
  &__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-bottom: 32px;

    @include mobile {
      display: block;
      margin-bottom: 24px;
    }
  }

  // .u-lk-digest__errors
  &__errors {
    font-family: $font-family-inter;
    margin-bottom: 32px;
    color: $color-danger;
    max-height: 50px;
    overflow: hidden;
    transition: $transtion-default;

    @include mobile {
      margin-bottom: 24px;
    }

    // .u-lk-digest__errors--is-hidden
    &--is-hidden {
      max-height: 0;
      padding: 0;
      margin: 0;
      opacity: 0;
    }
  }

  // .u-lk-digest__field
  &__field {
    margin-top: auto;

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 23px;
      }
    }

    .v-select {
      ul {
        & > *:first-child {
          display: none !important;
        }
      }
    }
  }

  // .u-lk-digest__field-label
  &__field-label {
    font-family: $font-family-inter;
    font-size: 12px;
    line-height: 1.3;
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;
    font-weight: normal;
  }

  // .u-lk-digest__footer
  &__footer {
    @include mobile {
      text-align: center;
    }
  }

  // .u-lk-digest__table,
  // .u-lk-digest__not-found
  &__table,
  &__not-found {
    margin-top: 64px;

    @include mobile {
      margin-top: 48px;
    }
  }
}
</style>
