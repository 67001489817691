<template>
  <div class="u-subscribe-block" :class="{ 'u-subscribe-block--is-subscribed': isSubscribed }">
    <div class="u-g-container">
      <div class="u-subscribe-block__wrapper">
        <UFigures
          :items="figures"
          position="static"
          :isAnimateImg="false"
          :fullWidth="true"
          startPosition="center bottom"
        >
          <div class="u-subscribe-block__inner">
            <template v-if="isSubscribed">
              <div class="u-subscribe-block__success-message tt-uppercase title-lg" v-html="successText" />
            </template>
            <template v-else>
              <div class="u-subscribe-block__content">
                <slot />
              </div>
              <form class="u-subscribe-block__form" @submit.prevent="onSubmit">
                <div class="u-subscribe-block__field">
                  <input
                    type="text"
                    class="u-subscribe-block__input"
                    :class="{'u-subscribe-block__input--is-error': isRequiredErrorField || isEmailErrorField || isGmailErrorField }"
                    placeholder="Ваш e-mail"
                    v-model="email"
                  >
                  <ErrorFormWrapper :isVisible="isRequiredErrorField || isEmailErrorField || isGmailErrorField">
                    <div v-show="isRequiredErrorField">Обязательное поле</div>
                    <div v-show="isEmailErrorField">Неправильный e-mail</div>
                    <div v-show="isGmailErrorField && !isEmailErrorField">Укажите почту в российской зоне .ru</div>
                  </ErrorFormWrapper>
                </div>

                <ErrorFormWrapper
                  class="u-subscribe-block__feedback-errors"
                  :isVisible="Boolean(errorsAfterSubmit) && Boolean(errorsAfterSubmit.length)"
                >
                  <div
                    v-for="error in errorsAfterSubmit"
                    :key="`u-subscribe-block__feedback-error-${error.code}`"
                    class="u-subscribe-block__feedback-error"
                  >
                    <span v-html="error.message" />
                  </div>
                </ErrorFormWrapper>

                <CustomButton
                  class="u-subscribe-block__btn"
                  theme="primary"
                  size="2md"
                  :status="btnStatus"
                  @click.prevent="onSubmit"
                >
                  Подписаться
                </CustomButton>
              </form>
            </template>
          </div>
        </UFigures>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { checkGmail } from '@/components/helpers/validate.js'
import axios from 'axios';

export default {
  mixins: [validationMixin],
  props: {
    actionSend: {
      type: String,
      default: ''
    },
    successText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      email: '',
      figures: [
        {
          name: 'u-subscribe-block',
          image: '/frontend/assets/img/bg-shapes/circle-triplet.svg',
          animation: 'fade-in-left'
        },
        {
          name: 'u-subscribe-block-mob',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        },
      ],
      btnStatus: '',
      errorsAfterSubmit: [],
      LSName: '_subscribedNewsletter',
      isSubscribed: false
    }
  },
  validations: {
    email: { required, email, isGmail: checkGmail }
  },
  computed: {
    isGmailErrorField() {
      return this.$v.email.$error && !this.$v.email.isGmail
    },
    isRequiredErrorField() {
      return this.$v.email.$error && !this.$v.email.required
    },
    isEmailErrorField() {
      return this.$v.email.$error && !this.$v.email.email
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return
      }

      try {
        this.btnStatus = 'loading'

        const data = new FormData()
        data.append('email', this.email)

        const options = {
          method: 'post',
          url: this.actionSend,
          data
        }

        const response = await axios(options)

        if (response.data.status === 'success') {
          this.errorsAfterSubmit = []
          this.saveInLS()
          this.checkHideStatus();
        } else {
          this.errorsAfterSubmit = response.data.errors
        }
      } catch(e) {
        throw new Error('USubscribeBlock:onSubmit', e)
      } finally {
        this.btnStatus = ''
      }
    },
    saveInLS() {
      localStorage.setItem(this.LSName, 'true')
    },
    checkHideStatus() {
      this.isSubscribed = !!localStorage.getItem(this.LSName)
    }
  },
  created() {
    this.checkHideStatus()
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-subscribe-block';

#{$b} {

  // .u-subscribe-block__title
  &__title {
    font-family: $font-family-fugue;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  // .u-subscribe-block__description
  &__description {
    margin: 0;
  }

  // .u-subscribe-block__wrapper
  &__wrapper {
    position: relative;
    border-radius: 16px;
    background-color: $color-primary-light-2;
    overflow: hidden;
  }

  // .u-subscribe-block__inner
  &__inner {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: auto 381px;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    padding: 32px;

    @include tablet {
      display: block;
    }

    #{$b}--is-subscribed & {
      display: block;
    }
  }

  // .u-subscribe-block__content
  &__content {
    @include tablet {
      margin-bottom: 24px;
    }
  }

  &__form {
    @include tablet {
      max-width: 450px;
    }

    @include mobile {
      width: 100%;
      max-width: 100%;
    }
  }

  // .u-subscribe-block__field
  &__field {
    margin-bottom: 16px;
  }

  // .u-subscribe-block__input
  &__input {
    width: 100%;
    height: 48px;
    padding: 10px 24px;
    border: 1px solid $color-primary;
    border-radius: 40px;
    background-color: $white-true;
  }

  // .u-subscribe-block__btn
  &__btn {
    width: 100%;
  }

   // .u-subscribe-block__feedback-error
  &__feedback-error {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}
</style>
