<template>
  <div
    class="tabs-list-dropdown"
    :class="[
      `tabs-list-dropdown--theme-${theme} tabs-list-dropdown--size-${size}`,
      { 'tabs-list-dropdown--is-static': isStatic }
    ]"
    :style="rootStyle"
  >
    <div
      class="tabs-list-dropdown__label"
      :class="{'tabs-list-dropdown__label--is-opened': isOpened}"
      @click="toggle"
    >
      <span>{{ activeTab?.title || ''}}</span>
      <inline-svg class="tabs-list-dropdown__icon" src="u-arr-down" />
    </div>
    <div class="tabs-list-dropdown__options" :class="{'tabs-list-dropdown__options--is-visible': isOpened}">
      <div ref="dropdownWrapper" class="tabs-list-dropdown__options-wrapper" :class="{'tabs-list-dropdown__options-wrapper--has-scroll': hasScroll}">
        <div
          class="tabs-list-dropdown__option"
          :class="{'tabs-list-dropdown__option--is-active': item[uniqueKey] === value}"
          v-for="(item, i) in filteredTabs"
          :key="`tabs-list-dropdown__option-${item[uniqueKey]}`"
          @click="selectTab(item[uniqueKey], i)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, String],
      default: ''
    },
    uniqueKey: {
      type: String,
      default: 'id'
    },
    theme: {
      type: String,
      default: 'primary-outline' // primary-outline | light-outline | light-primary
    },
    size: {
      type: String,
      default: 'default' // default | lg
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpened: false,
      hasScroll: false,
      settingsSize: {
        default: {

        },
        lg: {
          scrollHeight: 180
        }
      },
      prevInnerWidth: 0,
    }
  },
  computed: {
    activeTab() {
      return this.tabs.find(tab => tab[this.uniqueKey] === this.value)
    },
    filteredTabs() {
      return this.tabs.filter(item => item[this.uniqueKey] !== this.value)
    },
    currentSettingsSize() {
      return this.settingsSize && Object.hasOwn(this.settingsSize, this.size) ? this.settingsSize[this.size] : this.settingsSize.default
    },
    scrollHeight() {
      return Object.hasOwn(this.currentSettingsSize, 'scrollHeight') ? this.currentSettingsSize.scrollHeight : 0
    },
    canScroll() {
      return this.scrollHeight > 0
    },
    rootStyle() {
      return {
        '--scroll-height': `${this.scrollHeight}px`
      }
    }
  },
  methods: {
    toggle() {
      this.isOpened = !this.isOpened
    },
    selectTab(id, i) {
      this.$emit('input', id, i)
      this.isOpened = false
    },
    closeMobMenu(e){
      const el = e.target.closest('.tabs-list-dropdown')

      if (!(e.target.classList.contains('tabs-list-dropdown') || (el && (el === this.$el)))) {
        this.isOpened = false
      }
    },
    checkScroll() {
      const mainCondition = this.canScroll && this.$refs.dropdownWrapper
      this.hasScroll = mainCondition && this.$refs.dropdownWrapper.scrollHeight > this.scrollHeight
    },
    resizeHandle() {
      if (this.prevInnerWidth === window.innerWidth) {
        return
      }

      this.checkScroll()
      this.prevInnerWidth = window.innerWidth
    }
  },
  mounted() {
    document.addEventListener('click', this.closeMobMenu)
    window.addEventListener('resize', this.resizeHandle)

    this.$nextTick(() => {
      this.checkScroll()
    })
  },
  beforeDestroy(){
    if (!this.dropDownOnMobile) {
      return
    }

    window.removeEventListener('resize', this.resizeHandle)
    document.removeEventListener('click', this.closeMobMenu)
  }
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.tabs-list-dropdown';

#{$b} {
  position: relative;
  font-family: $font-family-inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;

  &--size-lg {
    font-size: 16px;
    font-weight: normal;
  }

  // .tabs-list-dropdown__label
  &__label {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 24px;
    border: 1px solid transparent;
    border-radius: 24px;
    background: transparent;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

    #{$b}--theme-primary-outline & {
      border-color: $color-primary;
      color: $color-primary;
    }

    #{$b}--theme-light-outline & {
      border-color: $white-true;
      color: $white-true;
    }

    #{$b}--theme-light-primary & {
      border-color: $white-true;
      background-color: $white-true;
      color: $color-primary;
    }

    #{$b}--size-lg & {
      // height: 45px;
      padding: 10px 15px;
      border-radius: 20px;
    }

    // .tabs-list-dropdown__label--is-opened
    &--is-opened {
      #{$b}--theme-primary-outline &,
      #{$b}--theme-light-primary & {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      #{$b}--theme-light-primary & {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 15px;
          right: 15px;
          height: 1px;
          background-color: rgba($color-primary, 0.1);
        }
      }

      #{$b}__icon {
        transform: rotate(180deg)
      }
    }
  }

  // .tabs-list-dropdown__options
  &__options {
    width: 100%;
    height: auto;
    position: absolute;
    top: calc(100% + 8px);
    left:0;
    padding: 3px 24px;
    background: $white-true;
    color: $black-true;
    border-radius: 24px;
    z-index: 2;
    box-shadow: $shadow-default;

    #{$b}--size-lg & {
      border-radius: 20px;
      padding: 12px 16px;
    }

    #{$b}--theme-primary-outline &,
    #{$b}--theme-light-primary & {
      top: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    #{$b}--theme-light-primary & {
      color: $color-primary;
    }

    &--is-visible {
      #{$b}--is-static & {
        position: static;
      }
    }

    &:not(#{$b}__options--is-visible) {
      opacity: 0 !important;
      pointer-events: none;
      touch-action: none;
    }

    // .tabs-list-dropdown__options-wrapper
    &-wrapper {

      // .tabs-list-dropdown__options-wrapper--has-scroll
      &--has-scroll {
        #{$b}--theme-light-primary & {
          padding-right: 16px;

          max-height: var(--scroll-height);
          overflow-x: hidden;
          overflow-y: auto;
          @include customScrollBar(3px, $color-primary, transparent, 20px);
        }
      }
    }
  }


  // .tabs-list-dropdown__option
  &__option {
    padding: 7px 0;

    #{$b}--size-lg & {
      padding: 12px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    #{$b}--theme-light-primary & {
      &:not(:last-child) {
        border-bottom: 1px solid rgba($color-primary, 0.1);
      }
    }

    &--is-active {
      opacity: 0.5;

      #{$b}--theme-light-primary & {
        display: none;
      }
    }
  }

  // .tabs-list-dropdown__icon
  &__icon {
    margin: 0 0 0 20px;
    width: 10px;
    height: 10px;
    object-fit: contain;
    object-position: center;
    transition: $transtion-default;

    #{$b}--size-lg & {
      width: 13px;
      height: 13px;
    }

    svg {
      path {
        fill: transparent !important;
        stroke: currentColor;
      }
    }
  }
}
</style>
