<template>
  <div v-if="initItems && initItems.length" class="initiatives-events">
    <div class="initiatives-events__header">
      <h2 class="initiatives-events__title title-xl tt-uppercase">
        Проекты и мероприятия
      </h2>
      <CustomButton
        v-if="!isEditable"
        theme="primary"
        size="md"
        tag="a"
        href="https://xn--80axfis.xn--80aa3ak5a.xn--p1ai/"
        target="_blank"
      >
        Предложить проект или мероприятие
      </CustomButton>
    </div>
    <!-- <div v-if="isExistsFilter" class="initiatives-events__filter">
      <InitiativesEventsFilter
        :directionOptions="optionsDirections"
        :audienceOptions="optionsAudience"
        @onFilter="onFilter"
      />
    </div> -->
    <UEventsList
      v-if="items && items.length"
      class="initiatives-events__events-list"
      :items="items"
      :isHiddenBtn="isHiddenBtn"
      :isEditable="isEditable"
      @addNewEvent="addNewEvent"
      @remove="removeEvent"
      @edit="editEvent"
    />
    <not-found v-else text="По данным параметрам ничего не найдено" />
    <div class="initiatives-events__footer">
      <CustomButton
        v-if="NEXT_URL"
        class="initiatives-events__btn-more"
        theme="primary"
        size="md"
        :status="btnStatus"
        @click="onLoadMore"
      >
        Показать ещё
      </CustomButton>
      <!-- <CustomButton
        class="initiatives-events__all-events"
        tag="a"
        href="/events/"
        theme="outline-primary"
        size="md"
      >
        Все мероприятия
      </CustomButton> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import cloneDeep from 'lodash-es/cloneDeep'
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  inject: {
    isEditable: {
      from: 'isEditable',
      default: false
    },
  },
  props: {
    initItems: {
      type: Array,
      default: () => []
    },
    isHiddenBtn: {
      type: Boolean,
      default: false
    },
    initiativeId: {
      type: String,
      default: ''
    },
    actionSave: {
      type: String,
      default: ''
    },
    actionDelete: {
      type: String,
      default: ''
    },
    actionGet: {
      type: String,
      default: ''
    },
    actionUpdate: {
      type: String,
      default: ''
    },
    actionGetOptions: {
      type: String,
      default: ''
    },
    actionFilter: {
      type: String,
      default: '',
    },
    dictDirections: {
      type: Array,
      default: () => []
    },
    dictAudience: {
      type: Array,
      default: () => []
    },
    initNextUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      items: cloneDeep(this.initItems),
      optionsDirections: cloneDeep(this.dictDirections),
      optionsAudience: cloneDeep(this.dictAudience),
    }
  },
  computed: {
    ...mapGetters('initiativesEvents', ['ITEMS', 'IS_LOADING', 'NEXT_URL']),
    isExistsFilter() {
      return (this.dictDirections && this.dictDirections.length) || (this.dictAudience && this.dictAudience.length)
    },
    btnStatus() {
      return this.IS_LOADING ? 'loading' : ''
    },
  },
  methods: {
    ...mapActions('initiativesEvents', ['LOAD_ITEMS']),
    ...mapMutations('initiativesEvents', ['SET_NEXT_URL']),

    async addNewEvent() {
      this.$root.$refs.customModal.openModal(null, null, true, true);

      const props = await this.getFormProps(this.actionGetOptions)
      if (!props) {
        this.$root.$refs.customModal.closeModal()
        return
      }

      this.$root.$refs.customModal.passContent({
        name: 'u-event-form',
        props: {
          actionSave: this.actionSave,
          ...props,
          initiativeId: this.initiativeId
        },
        title: 'добавить проект или мероприятие',
      })
    },
    async removeEvent(id) {
      const options = {
        url: this.actionDelete,
        method: 'POST',
        data: {
          initiativeId: this.initiativeId,
          idDelete: id,
        }
      };

      const response = await axios(options);

      if (response.data.status === 'success') {
        this.items = this.items.filter(item => item.id !== id)
      } else {
        const error = response.data.errors
          .map(item => item.message)
          .join('. ')

        alert(error)
      }
    },
    async editEvent(id) {
      this.$root.$refs.customModal.openModal(null, null, true, true);

      const props = await this.getFormProps(`${this.actionGet}&id=${id}`)
      if (!props) {
        this.$root.$refs.customModal.closeModal()
        return
      }

      const type = props.type === 'project' ? 'проект' : 'мероприятие'

      this.$root.$refs.customModal.passContent({
        name: 'lk-plan-form',
        props: {
          submitUrl: this.actionUpdate,
          requestId: id,
          ...props,
          reloadAfterSuccessReponse: true
        },
        title: `Редактировать ${type}`,
      })
    },
    async getFormProps(url) {
      const response = await axios(url)

      if (response.data.status === 'success') {
        return response.data.data
      } else {
        const error = response.data && response.data.errors ? response.data.errors
          .map(item => item.message)
          .join('. ')
          : 'Произошла непредвиденная ошибка'
        alert(error)
      }
    },
    updateEventsList(response) {
      if (response.type === 'update') {
        const index = this.items.findIndex(item => item.id === response.data.id)
        this.items = [...this.items.slice(0, index), response.data, ...this.items.slice(index + 1)]
      } else {
        this.items = [response.data, ...this.items]
      }
    },
    async onFilter(form) {
      const response = await axios(`${this.actionFilter}?directions=${form.direction}&audience=${form.audience}`)

      if (response && (response.statusText === 'OK' || response.status === 200)) {
        this.items = response.data.ITEMS
        this.optionsDirections = response.data.filter.direction
        this.optionsAudience = response.data.filter.audience
      }
    },
    async onLoadMore() {
      const response = await this.LOAD_ITEMS()

      if (response.ITEMS) {
        this.items = [...this.items, ...response.ITEMS]
      }
    }
  },
  created() {
    this.SET_NEXT_URL(this.initNextUrl)
  },
  mounted() {
    this.$root.$on('editedEventsList', this.updateEventsList)
  },
  beforeDestroy() {
    this.$root.$off('editedEventsList', this.updateEventsList)
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.initiatives-events';

#{$b} {

  // .initiatives-events__header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    @include tablet {
      display: block;
      margin-bottom: 24px;
    }
  }

  // .initiatives-events__filter
  &__filter {
    margin-bottom: 32px;

    @include tablet {
      margin-bottom: 24px;
    }
  }

  // .initiatives-events__title
  &__title {
    font-family: $font-family-fugue;
    margin: 0 0;

    @include tablet {
      margin-bottom: 24px;
    }
  }

  // .initiatives-events__events-list
  &__events-list {
    margin-bottom: 32px;

    @include tablet {
      margin-bottom: 24px;
    }
  }

  // .initiatives-events__footer
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  // .initiatives-events__all-events
  &__all-events {
    margin-left: auto;
  }

  // .initiatives-events__btn-more
  &__btn-more {
    @include mobile {
      margin-bottom: 24px;
    }
  }
}
</style>
