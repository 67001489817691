arr<template>
  <component
    :is="tag"
    class="arrow-link"
    :class="{
      'arrow-link--left': direction === 'left',
      'arrow-link--right': direction === 'right'
    }"
    :href="tag === 'a' ? href : false"
  >
    <template v-if="direction === 'left'">
      <inline-svg src="arr-left" />
    </template>
    <span :class="textClass" class="tt-uppercase fw-500">
      <slot />
    </span>
    <template v-if="direction === 'right'">
      <inline-svg src="arr-right" />
    </template>
  </component>
</template>

<script>
export default {
  name: "ArrowLink",
  props: {
    tag: {
      type: String,
      default: "a"
    },
    href: {
      type: String,
      default: "#"
    },
    direction: {
      type: String,
      default: "right"
    },
    textClass: {
      type: String,
      default: ""
    },
  }
};
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: ".arrow-link";

#{$b} {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: color 0.2s ease;

  &:hover,
  &:focus {
    color: $color-base;
  }

  .inline-svg {
    position: relative;
    transition: .2s ease-out;
    transition-property: left, right;

    path {
      fill: transparent !important;
      stroke: currentColor;
    }
  }

  &--left {
    &:hover,
    &:focus {
      .inline-svg {
        left: -10px;
      }
    }

    .inline-svg {
      left: 0;
      margin-right: 8px;
    }
  }

  &--right {
    &:hover,
    &:focus {
      .inline-svg {
        right: -6px;
      }
    }

    .inline-svg {
      right: 0;
      margin-left: 8px;
    }
  }

  .inline-svg {
    width: 29px;
    height: 16px;
  }
}
</style>
