<template>
  <div class="initiatives-playgrounds">
    <div class="initiatives-playgrounds__header">
      <h2 class="initiatives-playgrounds__title title-xl tt-uppercase">
        {{ title }}
      </h2>
      <div class="initiatives-playgrounds__filter u-select">
        <v-select
          v-model="selectedRegion"
          :clearable="false"
          :searchable="true"
          label="name"
          :reduce="option => option.id"
          :options="filterRegionOptions"
          @input="onFilterRegion"
        >
          <template #no-options>
            Не найдено
          </template>
        </v-select>
      </div>
    </div>
    <div class="initiatives-playgrounds__content">
      <UPlaygroundCard
        v-for="item in items"
        :key="`initiatives-playgrounds__card-${item.id}`"
        :data="item"
      ></UPlaygroundCard>
    </div>
    <div v-if="pageNumber && initialNextPage" class="initiatives-playgrounds__footer">
      <CustomButton
        size="md"
        theme="primary"
        :status="btnStatus"
        @click.prevent="showMoreHandler"
      >
        Показать ещё
      </CustomButton>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import SimpleFilterMixin from '/frontend/mixins/SimpleFilterMixin.js'

export default {
  name: 'InitiativesPlaygrounds',
  mixins: [ SimpleFilterMixin ],
  components: {
    vSelect
  },
  props: {
    title: {
      type: String,
      default: 'Построенные площадки'
    },
    filterRegionOptions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      selectedRegion: this.filterRegionOptions[0].id,
    }
  },
  methods: {
    onFilterRegion(id) {
      const filterQuery = `region=${id}`
      this.pageNumber = 1
      this.loadList(filterQuery, true)
    }
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

.initiatives-playgrounds {

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 24px;
      margin-bottom: 24px;
    }
  }

  &__title {
    font-family: $font-family-fugue;
    margin: 0 0;
  }

  &__filter {
    width: 400px;

    @include tablet {
      width: 100%;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include tablet {
      grid-template-columns: 1fr;
    }
  }

  &__footer {
    margin-top: 32px;

    @include tablet {
      margin-top: 24px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
