<template>
  <div class="initiatives-verificated">
    <div class="initiatives-verificated__header">
      <h2
        class="initiatives-verificated__title title-xl tt-uppercase"
        v-html="title"
      />
      <p
        class="initiatives-verificated__description text-lg"
        v-html="description"
      />
    </div>
    <div class="initiatives-verificated__body">
      <UPlaygroundCard
        v-for="item in items"
        :data="item"
        :size="item.size"
        cta-btn-text="Cмотреть"
        :key="`initiatives-verificated-item${item.id}`">
      </UPlaygroundCard>
    </div>
    <div v-if="pageNumber && initialNextPage" class="initiatives-verificated__footer" >
      <CustomButton
        size="md"
        theme="primary"
        :status="btnStatus"
        @click.prevent="showMoreHandler"
      >
        Показать ещё
      </CustomButton>
    </div>
  </div>
</template>

<script>
import SimpleFilterMixin from '/frontend/mixins/SimpleFilterMixin.js'

export default {
  name: "InitiativesVerificated",
  mixins: [ SimpleFilterMixin ],
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

.initiatives-verificated {
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    gap: 32px;

    @include mobile {
      margin-bottom: 48px;
      gap: 24px;
    }
  }

  &__title {
    font-family: $font-family-fugue;
    margin: 0 0;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__description {
    margin: 0 0;

    @include mobile {
      font-size: 18px;
    }
  }

  &__footer {
    margin-top: 32px;

    @include mobile {
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }
  }
}
</style>
