var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"events-slider"},[_c('div',{staticClass:"events-slider__inner g-wrapper-offset-left"},[_c('swiper',{ref:"eventsSwiper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.items),function(slide,i){return _c('swiper-slide',{key:i,class:{
          'is-slide-large': i == 0 || i == 7,
          'is-slide-colored': i == 1 || i == 6,
          'is-slide-background': i == 2 || i == 4,
          '_additional': slide.isAdditional,
          '_regional': slide.isRegional
        }},[_c('a',{staticClass:"events-slider__item",attrs:{"href":slide.url}},[_c('div',{staticClass:"events-slider__item-body"},[_c('div',{staticClass:"events-slider__item-date"},[_c('div',{staticClass:"events-slider__item-date-label"},[_c('inline-svg',{attrs:{"src":"clock"}}),_c('span',[_vm._v(_vm._s(slide.period))])],1),(slide.isAdditional)?_c('div',{staticClass:"events__item-additional"},[_vm._v("дополнительное мероприятие")]):_vm._e(),(slide.isRegional)?_c('div',{staticClass:"events__item-regional"},[_vm._v("региональное мероприятие")]):_vm._e()]),(slide.organizers)?_c('div',{staticClass:"events-slider__item-organizers"},[_c('span',[_vm._v("При поддержке: ")]),(slide.organizers.length)?_c('span',{domProps:{"innerHTML":_vm._s(slide.organizers.map(item => item.name).join(', '))}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"events-slider__item-title"},[_vm._v(" "+_vm._s(slide.title)+" ")]),_c('div',{staticClass:"events-slider__item-place"},[_c('span',[_vm._v(_vm._s(slide.place))])])]),(slide.image)?_c('div',{staticClass:"events-slider__item-media"},[_c('div',{staticClass:"events-slider__item-image",style:({ backgroundImage: `url('${slide.image}')` })})]):_vm._e()])])}),1),_c('div',{staticClass:"events-slider__controls-wrapper"},[_c('div',{staticClass:"events-slider__controls"},[_c('div',{staticClass:"events-slider__prev"},[_c('inline-svg',{attrs:{"src":"arrow-left"}})],1),_c('div',{staticClass:"events-slider__count swiper-pagination"}),_c('div',{staticClass:"events-slider__next"},[_c('inline-svg',{attrs:{"src":"arrow-right"}})],1)]),_c('div',{staticClass:"events-slider__bullets g-pagination-bullets"})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }