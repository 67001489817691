import "./scss/app.scss";
import "swiper/css/swiper.css";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

import Vue from "vue";
import registerVueDirectives from "./directives";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueDragscroll from "vue-dragscroll";
import VueRellax from "vue-rellax";
import VueSmoothScroll from "vue2-smooth-scroll";
import VBodyScrollLock from "v-body-scroll-lock";
import VueObjectFit from "vue-object-fit";
import CoolLightBox from "vue-cool-lightbox";
import vClickOutside from "v-click-outside";
// eslint-disable-next-line
import yaMetrics from './utils/yaMetrics'
import decodeCyrillicDomain from '@/components/helpers/decodeCyrillicDomain.js'

import store from './store'

registerVueDirectives();

Vue.use(VueAwesomeSwiper);
Vue.use(VueDragscroll);
Vue.use(VueRellax);
Vue.use(VueSmoothScroll);
Vue.use(VBodyScrollLock);
Vue.use(VueObjectFit);
Vue.use(CoolLightBox);
Vue.use(vClickOutside);

const files = require.context("./components", true, /\.vue$/i);
files.keys().map(key =>
  Vue.component(
    key
      .split("/")
      .pop()
      .split(".")[0],
    files(key).default
  )
);

const moment = require("moment");
require("moment/locale/ru");

Vue.use(require("vue-moment"), {
  moment
});

Vue.filter("formatNumber", function (val) {
  if (!val) {
    return 0;
  }
  val = parseFloat(parseFloat(val).toFixed(2));
  return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace(/[.]/g, ',');
});

Vue.filter("formatNumberLimit", function (val) {
  if (!val) {
    return 0;
  }
  val = parseFloat(parseFloat(val).toFixed(2));
  if (parseInt(val) > 100) {
    return 100;
  }
  else {
    return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace(/[.]/g, ',');
  }
});

Vue.filter("firstLetterUppercase", function (val) {
  if (!val) {
    return 0;
  }
  return val.charAt(0).toUpperCase() + val.slice(1);
});

new Vue({
  store,
  provide() {
    return {
      breakpoint: this.breakpoint,
      ymHandler: this.ymHandler,
      topMailRuHandler: this.topMailRuHandler,
      goToAuthForm: this.goToAuthForm,
      documentResizeObserver: null,
      headerResizeObserver: null
    };
  },
  data() {
    return {
      breakpoint: this.getBreakpoint(),
      previousPercentagesScroll: []
    };
  },
  mounted() {
    this.initEvents();

    this.$nextTick().then(() => {
      this.setRootSizes();
      this.setHeaderSizes();

      setTimeout(() => {
        this.scrollToBlockByHash()
      }, 1000)
    })
  },
  beforeDestroy() {
    this.removeEvents();
  },
  methods: {
    initEvents() {
      window.addEventListener('resize', this.updateBreakpoint);
      window.addEventListener('resize', this.setRootSizes)
      document.addEventListener('copy', this.addCitationAndLinkOnCopy);
      this.documentResizeObserver = new ResizeObserver(this.documentResizeObserverHandler)
      this.documentResizeObserver.observe(document.body)

      const header = document.querySelector('header')
      if (header) {
        this.headerResizeObserverHandler = new ResizeObserver(this.headerResizeObserverHandler)
        this.headerResizeObserverHandler.observe(header)
      }
      window.addEventListener("scroll", this.ymScrollDeepHandler);
    },
    removeEvents() {
      window.removeEventListener('resize', this.updateBreakpoint);
      window.removeEventListener('resize', this.setRootSizes)
      document.removeEventListener('copy', this.addCitationAndLinkOnCopy);
      this.documentResizeObserver?.unobserve(document.body)
      const header = document.querySelector('header')
      if (header) {
        this.headerResizeObserverHandler?.unobserve(header)
      }
      window.removeEventListener("scroll", this.ymScrollDeepHandler);
    },
    getBreakpoint() {
      const { innerWidth } = window;
      return {
        isMobile: innerWidth < 768,
        isTablet: innerWidth >= 768 && innerWidth < 1024,
        isDesktop: innerWidth >= 1024
      };
    },
    updateBreakpoint() {
      const { isMobile, isTablet, isDesktop } = this.getBreakpoint();
      this.breakpoint.isMobile = isMobile;
      this.breakpoint.isTablet = isTablet;
      this.breakpoint.isDesktop = isDesktop;
    },
    ymHandler(data) {
      const code = data.code || 93982301
      const type = data.type || 'reachGoal'
      const operation = data.operation || ''
      const options = data.options || {}

      const ym = [code, type, operation]

      if (options && Object.values(options).length) {
        ym.push(options)
      }

      window.ym(...ym)
    },
    goToAuthForm(tabAfterAuth) {
      this.$root.$refs.authModal.isShow = true;
      this.$root.$emit('burgerMenuToggle', false);
      if (tabAfterAuth) {
        this.$nextTick(() => {
          this.$root.$emit('goToTabAfterAuth', tabAfterAuth)
        })
      }
    },
    topMailRuHandler(data) {
      const _tmr = window._tmr || (window._tmr = []);
      const id = data.id || 3392683
      const type = data.type || 'reachGoal'
      const goal = data.goal || 'vote'

      _tmr.push({type, id, goal});
    },
    setRootSizes() {
      this.$nextTick().then(() => {
        document.documentElement.style.setProperty('--app-height', `${Math.ceil(window.innerHeight)}px`);
      })
    },
    setHeaderSizes() {
      this.$nextTick().then(() => {
        const header = document.querySelector('header')

        if (!header) {
          return
        }

        const headerHeight = header?.getBoundingClientRect()?.height || 0

        document.documentElement.style.setProperty('--header-height', `${Math.ceil(headerHeight)}px`);
      })
    },
    documentResizeObserverHandler(entries) {
      entries.forEach(() => {
        this.setRootSizes()
      });
    },
    headerResizeObserverHandler(entries) {
      entries.forEach(() => {
        this.setHeaderSizes()
      });
    },
    scrollToBlock(block, posY = 'start') {
      if (!block) {
        return
      }
      block.scrollIntoView({ behavior: 'smooth', block: posY })
    },
    scrollToBlockByHash() {
      this.$nextTick(() => {
        const hash = window.location.hash

        if (!hash) {
          return
        }

        const block = document.querySelector(`[data-scroll-anchor="${hash}"]`) || document.querySelector(hash)
        const posY = block?.getAttribute('data-scroll-position')

        this.scrollToBlock(block, posY || 'start')
      })
    },
    addCitationAndLinkOnCopy(e) {
      const selection = window.getSelection()
      const copiedText = selection.toString()
      const formattedUrl = decodeCyrillicDomain(window.location.href)
      const citation = `Подробнее:\n${formattedUrl}`
      const newText = copiedText + "\n\n" + citation

      e.clipboardData.setData('text/plain', newText)
      e.preventDefault()
    },
    ymScrollDeepHandler() {
      /*
        window.ymScrollDeep
        window.ymScrollDeepKey
        window.ymScroollHomePageOnly
        - задаются в include/header-meta-[project-name].html
      */
      const isHome = ['', '/'].includes(window.location.pathname);

      if (!window.ymScrollDeep || !window.ymScrollDeepKey || (window.ymScroollHomePageOnly && !isHome)) {
        return;
      }

      const maxScrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const percentagesArr = [25,50,75,90];
      const scrollY = document.documentElement.scrollTop;
      const scrollPercentage = Math.round(scrollY / maxScrollHeight * 100);
      let currentPercentage = 0;
      let i = 0;
      while (percentagesArr[i] <= scrollPercentage) {
        currentPercentage = percentagesArr[i++];
      }

      if (this.previousPercentagesScroll.includes(currentPercentage)) {
        return
      }

      this.ymHandler({
        code: window.ymScrollDeepKey,
        operation: `${currentPercentage}_scrollDepth`,
      });

      this.previousPercentagesScroll = [...this.previousPercentagesScroll, currentPercentage]
    },
  },
}).$mount("#app");
