<template>
  <div
    class="u-excursion-card"
    :class="{
      'u-excursion-card--no-image': !data.image,
      'u-excursion-card--group-is-full': groupIsFull,
      'u-excursion-card--is-past': data.isPast,
    }"
    @click="signUpTour"
  >
    <div class="u-excursion-card__header">
      <div
        v-if="data.image"
        class="u-excursion-card__img"
        :style="`background-image:linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)), url('${data.image}')`"
      ></div>
    </div>
    <div class="u-excursion-card__body">
      <div class="u-excursion-card__data-wrapper u-excursion-card__top-line">
        <div
          v-if="Boolean(data.region)"
          class="u-excursion-card__region tt-uppercase"
        >
          {{ data.region }}
        </div>
        <div
          v-if="groupIsFull"
          class="u-excursion-card__group-is-full tt-uppercase"
        >
          Группа набрана
        </div>
        <div v-if="data.dateYear && data.isPast" class="u-excursion-card__year">
          {{ data.dateYear }}
        </div>
      </div>
      <div class="u-excursion-card__data-wrapper">
        <div
          v-if="Boolean(data.date) || Boolean(data.time)"
          class="u-excursion-card__time tt-uppercase"
        >
          {{ formatTime(data.date, data.time) }}
        </div>
        <div
          v-if="!data.isPast"
          class="u-excursion-card__slots tt-uppercase"
        >
          Осталось <strong>{{ data.slots_left }}</strong> {{ declinedSlotsLabel }}
        </div>
      </div>
      <div
        v-if="Boolean(data.partners) && Boolean(data.partners.length)"
        class="u-excursion-card__partners"
      >
        <img
          v-for="(partner,i) in data.partners"
          :key="`u-excursion-card__partner-logo-${i}`"
          class="u-excursion-card__partner-logo"
          :src="partner"
          :alt="data.title"
        >
      </div>
      <h3
        class="u-excursion-card__title tt-uppercase"
        v-html="data.title"
      ></h3>
      <p
        v-if="Boolean(data.text)"
        class="u-excursion-card__text"
        v-html="data.text"
      ></p>
      <div v-if="!data.isPast" class="u-excursion-card__actions">
        <CustomButton class="u-excursion-card__btn">
          {{ groupIsFull ? 'Запись закрыта' : 'Записаться' }}
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['submitUrl', 'categoryOptions', 'ymHandler'],
  props: {
    data: {
      type: Object,
      require: true,
      default: () => ({ slots_left: 0 })
    },
  },
  computed: {
    declinedSlotsLabel() {
      return this.declineNumeral("slots", this.data.slots_left)
    },
    groupIsFull() {
      if (!this.data.slots_left) {
        return true
      }
      return  false
    }
  },
  methods: {
    formatTime(date, time) {
      let list = [];
      if (date) {
        list.push(date);
      }
      if (time) {
        list.push(time);
      }
      return list.join(' | ');
    },
    declineNumeral(word_type, number) {
      const declensionData = {
        'slots': {
          'accusative_singular': 'место', // винительный падеж, единственное число, пример: 21 час
          'genitive_plural': 'мест', // родительный падеж, множественное число, пример: 48 часов
          'nominative_plural': 'места', // именительный падеж, множественное число, пример: 22 часа
        }
      }

      let number_string = number.toString();
      let last_id = number_string.length - 1;
      let last_symbol = number_string[last_id];
      let last_but_one_symbol = number_string[last_id - 1];
      let word_case;
      if (last_but_one_symbol == '1') { //10-19, 110-119 etc.
          word_case = 'genitive_plural';
      } else {
          switch (last_symbol) {
              case '1':
                  word_case = 'accusative_singular';
                  break;
              case '2':
              case '3':
              case '4':
                  word_case = 'nominative_plural';
                  break;
              default:
                  word_case = 'genitive_plural';
                  break;
          }
      }
      return declensionData[word_type][word_case];
    },
    signUpTour() {
      if (this.groupIsFull || this.data.isPast) {
        return
      }

      this.ymHandler({code: '95339628', type: 'reachGoal', operation: 'click_signUp'})

      const tourData = {
        ...this.data,
        slotsLabel: this.declinedSlotsLabel,
        resultDate: this.formatTime(this.data.date, this.data.time),
      }

      this.$root.$refs.customModal.openModal(null, null, true, true);
      this.$root.$refs.customModal.passContent({
        name: 'tour-block',
        props: {
          categoryOptions: this.categoryOptions,
          tourData,
          submitUrl: this.submitUrl,
          excursionCard: this,
        },
        title: this.data.title,
      });
    },
    decreaseNumberOfSlots() {
      this.data.slots_left --;
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-excursion-card';

$headerHeight: 289px;

#{$b} {
  position: relative;
  height: 100%;
  font-family: $font-family-inter;
  cursor: pointer;
  color: $white-true;
  text-decoration: none;
  display: block;
  transition: $transtion-default;
  border-radius: 16px;

  // .u-excursion-card--no-image
  &--no-image {
    background-color: $color-base;
  }

  // .u-excursion-card--group-is-full
  &--group-is-full {
    pointer-events: none;
    touch-action: none;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($black-true, 0.7);
      border-radius: 16px;
    }
  }

  // .u-excursion-card--is-past
  &--is-past {
    pointer-events: none;
    touch-action: none;
  }

  @include mobile {
    background-color: $color-base;
  }

  &:hover {
    @include mobile-min {
      transform: scale(1.01);
    }
  }

  // .u-excursion-card__header
  &__header {
    height: $headerHeight;
    overflow: hidden;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;

    @include mobile-min {
      transform: translateY(1px);
    }
    @include mobile {
      width: 100%;
      height: auto;
      aspect-ratio: 376/240;
    }
    #{$b}:not(#{$b}--no-image) & {
      @include mobile {
        overflow: unset;
      }
    }
  }

  // .u-excursion-card__img
  &__img {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include mobile {
      border-radius: 16px;
    }
  }

  // .u-excursion-card__body
  &__body {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    overflow: hidden;

    #{$b}--no-image & {
      background-color: transparent;
    }

    @include mobile-min {
      background-color: $color-base;
      height: calc(100% - $headerHeight);
    }
  }

  &__region {
    letter-spacing: 1.8px;
    margin-right: 24px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 4px 0;

    @include mobile-md {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  &__group-is-full {
    display: flex;
    align-items: center;
    height: 32px;
    font-family: $font-family-fugue;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    background: $color-base;
    border-radius: 16px;
    padding: 1px 8px 0;
    z-index: 1;
    margin-left: auto;

    @include mobile-md {
      margin-left: 0;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  &__top-line {
    position: absolute;
    left: 24px;
    top: 18px;
    width: calc(100% - 48px);
    height: 32px;
    align-items: center;

    @include mobile {
      top: 24px;
    }
  }

  &__data-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  // .u-excursion-card__time
  &__time {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.1em;
    margin-bottom: 16px;
    margin-right: 25px;
    flex-grow: 1;
    flex-shrink: 0;

    @include mobile {
      margin-bottom: 24px;
    }

    @include mobile-md {
      width: 100%;
    }
  }

  // .u-excursion-card__slots
  &__slots {
    font-size: 16px;
    font-weight: 500;
    flex-shrink: 0;
    flex-grow: 0;
    letter-spacing: 1.65px;
    line-height: 130%;
    margin-bottom: 23px;
    strong {
      background: $white-true;
      color: $color-primary;
      border-radius: 10px;
      padding: 0 6px 0 8px;
    }
  }

  // .u-excursion-card__partners
  &__partners {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include mobile {
      margin-bottom: 8px;
    }
  }

  // .u-excursion-card__partner-logo
  &__partner-logo {
    height: 58px;
    width: auto;
    margin-bottom: 18px;

    &:not(:last-child) {
      margin-right: 25px;
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  // .u-excursion-card__title
  &__title {
    font-family: $font-family-fugue;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    margin: 0 0 16px 0;
    width: 100%;
  }

  // .u-excursion-card__text
  &__text {
    flex-grow: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin: 0 0 24px 0;
    width: 100%;
  }

  // .u-excursion-card__actions
  &__actions {
    margin-top: auto;
    text-align: right;
    margin-left: auto;
  }

  &__btn {
    font-weight: 400;

    #{$b}--group-is-full & {
      text-decoration: line-through;
    }
  }

  &--group-is-full {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white-true;
      opacity: .7;
      border-radius: 16px;
    }
  }
}

</style>
