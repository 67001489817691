<template>
  <div
    v-if="Boolean(objectives.length) && Boolean(description)"
    class="initiatives-objectives"
  >
    <div
      v-if="Boolean(objectives.length)"
      class="initiatives-objectives__item"
    >
      <div class="initiatives-objectives__header">
        <h3 class="initiatives-objectives__title">
          Цели
        </h3>
        <CustomButton
          v-if="isEditable"
          theme="outline-primary"
          size="md"
          @click="edit()"
        >
          Редактировать
        </CustomButton>
      </div>
      <ul class="initiatives-objectives__list">
        <li
          class="initiatives-objectives__list-item"
          v-for="(item, i) in objectives"
          :key="`initiatives-objectives__list-item-${i}`"
        >
          {{item.text}}
        </li>
      </ul>
    </div>
    <div
      v-if="Boolean(description)"
      class="initiatives-objectives__item"
    >
      <div class="initiatives-objectives__header g-hidden-mobile">
        <h3 class="initiatives-objectives__title">
          Описание
        </h3>
      </div>
      <div
        class="initiatives-objectives__description u-typography"
        v-html="description">
      </div>
    </div>
    <div
      v-if="isExistsDocs"
      class="initiatives-objectives__item"
    >
      <div class="initiatives-objectives__docs-list">
        <UDoc
          v-for="(doc, n) in docs"
          :data="doc"
          :key="n"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    isEditable: {
      from: 'isEditable',
      default: false
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        objectives: [],
        description: ''
      })
    },
    docs: {
      type: Array,
      default: () => []
    },
    // Эндпоинт для редактирования Целей и описания
    submitUrl: {
      type: String,
      default: ''
    },
    initiativeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      objectives: this.data.objectives,
      description: this.data.description,
    }
  },
  computed: {
    isExistsDocs() {
      return !!this.docs && !!this.docs.length
    },
  },
  methods: {
    edit() {
      this.$root.$refs.customModal.openModal(null, null, true, true);

      this.$root.$refs.customModal.passContent({
        name: 'initiatives-objectives-form',
        props: {
          initialObjectives: this.objectives || {},
          initialDescription: this.description || '',
          submitUrl: this.submitUrl,
          initiativeId: this.initiativeId
        },
        title: 'Редактировать Цели и описание'
      });
    },
    updateData(data) {
      this.objectives = data.objectives
      this.description = data.description
    }
  },
  mounted() {
    if (this.isEditable) {
      this.$root.$on('objectivesUpdate', this.updateData)
    }
  },
  beforeDestroy() {
    if (this.isEditable) {
      this.$root.$off('objectivesUpdate', this.updateData)
    }
  }
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: ".initiatives-objectives";

#{$b} {
  padding-bottom: 32px;
  border-bottom: 1px solid $black-true;

  @include mobile {
    padding-bottom: 24px;
    //margin-bottom: 24px;
  }

  // .initiatives-objectives__item
  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 64px;

      @include mobile {
        margin-bottom: 24px;
      }
    }

    &--lg-mb {
      margin-bottom: 64px !important;

      @include mobile {
        margin-bottom: 48px !important;
      }
    }

    .initiatives-page-detail__bg-edges {
      top: -180px;

      @include mobile {
        display: none;
      }
    }
  }

  // .initiatives-objectives__header
  &__header {
    margin: 0 0 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      display: block;
      margin-bottom: 32px;
    }
  }

  // .initiatives-objectives__title
  &__title {
    font-family: $font-family-fugue;
    font-weight: 500;
    font-size: 44px;
    line-height: 100%;
    margin: 0;
    text-transform: uppercase;

    // .initiatives-objectives__title--other
    &--other {
      @include mobile {
        font-size: 32px;
      }
    }

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .initiatives-objectives__list
  &__list {
    margin: 0;
    font-family: $font-family-fugue;
    font-weight: 500;
    font-size: 28px;
    line-height: 100%;
    text-transform: uppercase;
    padding-left: 35px;

    li {
      position: relative;

      &:before {
        content: '\25CF';
        position: absolute;
        top: -2px;
        left: -32px;
        font-size: 25px;

        @include mobile {
          top: 0;
          font-size: 18px;
        }
      }
    }

    @include mobile {
      font-family: $font-family-inter;
      font-size: 22px;
      line-height: 130%;
    }
  }

  // .initiatives-objectives__list-item
  &__list-item {
    &:not(:last-child) {
      margin:0 0 32px;
    }
  }

  // .initiatives-objectives__description
  &__description {

    &-list {
      padding-left: 24px !important;

      @include mobile {
        padding-left: 18px !important;
      }

      li {
        position: relative;

        &:before {
          content: '\25CF';
          position: absolute;
          top: 2px;
          left: -24px;
          font-size: 12px;

          @include mobile {
            left: -18px;
            font-size: 10px;
          }
        }
      }
    }
  }

  &__double {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;

    > * {
      width: calc(50% - 16px);

      &:first-child {
        margin-right: 16px;

        @include mobile {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }

      &:last-child {
        margin-left: 16px;

        @include mobile {
          margin-left: 0;
        }
      }

      @include mobile {
        width: 100%;
      }
    }
  }

  &__docs-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;

    @include mobile {
      gap: 24px;
      margin-bottom: 24px;
    }
  }
}
</style>
