<template>
  <a
    :href="data.url"
    target="_blank"
    class="u-institution-card"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div
      class="u-institution-card__column-left"
      :class="columnLeftWidthClassModifier"
    >
      <div v-if="data.region" class="u-institution-card__location fw-500 tt-uppercase">{{ data.region }}</div>
      <h3 class="u-institution-card__name title-md tt-uppercase">{{ data.name }}</h3>
      <div>
        <custom-button class="u-institution-card__btn">
          {{ buttonLabel }}
        </custom-button>
      </div>
    </div>
    <div
      v-if="data.img"
      class="u-institution-card__column-right"
      :class="columnRightWidthClassModifier"
    >
      <img class="u-institution-card__image" :src="data.img">
    </div>
  </a>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    buttonLabel: {
      type: String,
      required: true
    },
    widthClassModifier: {
      type: String,
      default: ''
    },
    isWide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    columnLeftWidthClassModifier() {
      return this.isWide ? 'u-institution-card__column-left_wide' : null
    },
    columnRightWidthClassModifier() {
      return this.isWide ? 'u-institution-card__column-right_wide' : null
    }
  }
};
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-institution-card';

#{$b} {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  background: $color-base-origin;
  height: 100%;
  color: $white-true;
  cursor: pointer;
  text-decoration: none;

  @include mobile {
    flex-direction: column;
  }

  &__column-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 24px;
    &:last-child {
      width: 100%;
    }

    @include tablet {
      padding: 24px 48px 24px 24px;
    }

    &_wide {
      padding: 24px 48px 24px 24px;
    }

    @include mobile {
      padding: 24px;

      &:last-child {
        height: 100%;
      }
    }
  }

  &__column-right {
    position: relative;
    width: 41.283%;
    border-radius: 16px;
    flex: none;
    overflow: hidden;

    @include tablet {
      width: 39.367%;
    }

    &_wide {
      width: 39.367%;
    }

    @include mobile {
      width: 100%;
      height: auto;
      aspect-ratio: 257/246;
      order: -1;
    }
  }

  &__location  {
    flex-grow: 1;
    padding-bottom: 12px;
    letter-spacing: 1.8px;
  }

  &__name {
    font-family: $font-family-fugue;
    margin: 0 0 24px 0;

    @include mobile {
      font-size: 20px;
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include mobile {
      position: static;
    }
  }

  &__btn {
    @include mobile {
      width: 100%;
    }
  }
}
</style>
