<template>
  <a
    class="u-simple-card"
    :href="data.url"
    v-bind="$attrs"
    v-on="$listeners"
    @click="$emit('ymSend')"
  >
    <h3 class="u-simple-card__name title-lg tt-uppercase">{{ data.name }}</h3>
    <div v-if="data.qualification" class="u-simple-card__qualification">
      {{ data.qualification }}
    </div>
    <p class="u-simple-card__description">{{ data.description }}</p>
    <CustomButton
      v-if="buttonLabel"
      class="u-simple-card__btn"
      theme="light"
    >
      {{ buttonLabel }}
    </CustomButton>
  </a>
</template>

<script>
export default {
  inject: ['breakpoint'],
  props: {
    buttonLabel: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      required: true
    },
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-simple-card';

#{$b} {
  min-height: 305px;
  display: flex;
  flex-direction: column;
  background-color: $color-base-origin;
  color: $white-true;
  border-radius: 16px;
  padding: 24px;
  text-decoration: none;
  cursor: pointer;

  * {
    text-decoration: none;
  }

  @include mobile {
    padding: 16px;
    min-height: unset;
  }

  // .u-simple-card__name
  &__name {
    margin: 0;

    @include mobile {
      font-size: 20px;
    }
  }

  // .u-simple-card__qualification
  &__qualification {
    padding: 3px 8px;
    border-radius: 16px;
    border: 1px solid $white-true;
    align-self: flex-start;
    margin-top: 12px;
  }

  // .u-simple-card__description
  &__description {
    margin: 20px 0;

    @include mobile {
      margin: 16px 0;
    }
  }

  // .u-simple-card__btn
  &__btn {
    margin-top: auto;
    align-self: flex-start;

    @include mobile {
      width: 100%;
    }
  }
}
</style>
