<template>
  <a
    class="initiatives-card"
    :href="card.url"
    :target="card.target"
  >
    <div class="initiatives-card__inner">
      <div class="initiatives-card__header">
        <div class="initiatives-card__label">
          <div v-if="Boolean(card.type)">
            {{ card.type }}
          </div>
        </div>
        <img
          v-if="Boolean(card.image)"
          class="initiatives-card__img"
          :src="card.image"
          :alt="card.title"
        >
      </div>
      <div class="initiatives-card__body">
        <h3 class="initiatives-card__title" v-html="card.title">
        </h3>
        <p
          v-if="card.text"
          class="initiatives-card__text"
          v-html="card.text"
        ></p>
      </div>
      <div class="initiatives-card__footer">
        <CustomButton
        >
          Узнать больше
        </CustomButton>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      require: true
    }
  }
}
</script>

<style lang="scss">
@import '../scss/base/u-includes.scss';

.initiatives-card {
  font-family: $font-family-inter;
  color: $white-true;
  display: block;
  text-decoration: none;

  &:hover {
    @include mobile-min {
      .initiatives-card__inner {
        transform: scale(1.01)
      }
    }
  }

  &:nth-child(8n+1),
  &:nth-child(4n+4),
  &:nth-child(8n+5) {
    .initiatives-card__inner {
      background: $gradient-purple;
    }
  }

  &:nth-child(4n+4) {
    .initiatives-card__inner {
      &::before{
        opacity: 1;
        background-image: url('/frontend/assets/img/initiatives/bg-lines.png');
        mix-blend-mode: color;
      }
    }
  }

  &:nth-child(8n+5) {
    .initiatives-card__inner {
      &::before{
        opacity: 1;
        background-image: url('/frontend/assets/img/initiatives/bg-circles.png');
        mix-blend-mode: lighten;
      }
    }
  }

  // .initiatives-card__inner
  &__inner {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    background-color: $color-base;
    padding: 24px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    transition: $transtion-default;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
      pointer-events: none;
      touch-action: none;
      opacity: 0;

      @include mobile {
        display: none;
      }
    }

    @include mobile-min {
      min-height: 400px;
    }
  }

  // .initiatives-card__header
  &__header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  // .initiatives-card__img
  &__img {
    margin-left: 14px;
    width: 90px;
    height: 90px;
    object-fit: contain;
    object-position: center;

    @include mobile {
      display: none;
    }
  }

  // .initiatives-card__label
  &__label {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.1em;
    text-transform: uppercase;

  }

  // .initiatives-card__body
  &__body {
    margin-top: auto;
  }

  // .initiatives-card__title
  &__title {
    font-family: $font-family-fugue;
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    margin:0;
    text-transform: uppercase;
    word-break: break-word;
  }

   // .initiatives-card__text
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    margin: 16px 0 0 0;
  }

  // .initiatives-card__footer
  &__footer {
    margin-top: 24px;
  }

}
</style>
