<template>
  <div class="events-slider">
    <div class="events-slider__inner g-wrapper-offset-left">
      <swiper ref="eventsSwiper" :options="swiperOptions">
        <swiper-slide
          v-for="(slide, i) in items"
          :key="i"
          :class="{
            'is-slide-large': i == 0 || i == 7,
            'is-slide-colored': i == 1 || i == 6,
            'is-slide-background': i == 2 || i == 4,
            '_additional': slide.isAdditional,
            '_regional': slide.isRegional
          }"
        >
          <a :href="slide.url" class="events-slider__item">
            <div class="events-slider__item-body">
              <div class="events-slider__item-date">
                <div class="events-slider__item-date-label">
                  <inline-svg src="clock" />
                  <span>{{ slide.period }}</span>
                </div>
                <div class="events__item-additional" v-if="slide.isAdditional">дополнительное мероприятие</div>
                <div class="events__item-regional" v-if="slide.isRegional">региональное мероприятие</div>
              </div>
              <div class="events-slider__item-organizers" v-if="slide.organizers">
                <span>При поддержке: </span>
                <span v-if="slide.organizers.length" v-html="slide.organizers.map(item => item.name).join(', ')"/>
              </div>
              <div class="events-slider__item-title">
                {{ slide.title }}
              </div>
              <div class="events-slider__item-place">
                <span>{{ slide.place }}</span>
              </div>
            </div>
            <div v-if="slide.image" class="events-slider__item-media">
              <div
                class="events-slider__item-image"
                :style="{ backgroundImage: `url('${slide.image}')` }"
              ></div>
            </div>
          </a>
        </swiper-slide>
      </swiper>
      <div class="events-slider__controls-wrapper">
        <div class="events-slider__controls">
          <div class="events-slider__prev">
            <inline-svg src="arrow-left" />
          </div>
          <div class="events-slider__count swiper-pagination"></div>
          <div class="events-slider__next">
            <inline-svg src="arrow-right" />
          </div>
        </div>
        <div class="events-slider__bullets g-pagination-bullets"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "EventsSlider",
  props: {
    initialItems: {
      type: Array,
      default: () => [
        {
          date: "2021-04-18 09:00",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }],
          title: "Фестиваль «Техносреда»",
          region: "Центральный регион",
          place: "Москва, Ленина 45/2, 5 этаж",
          image: "/frontend/assets/img/events/events-news-04.jpg",
        },
        {
          date: "2021-04-29 15:40",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }],
          title: "Международный кибертурнир «Битва за науку»",
          region: "Центральный регион",
          place: "Санкт-Петербург, Карла Либкнехта 12",
          image: "/frontend/assets/img/events/events-news-01.jpg",
        },
        {
          date: "2021-04-20 14:01",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }],
          title: "Торжественное мероприятие, посвящен- ное началу строительства Центра коллективного пользования «Сибирский кольцевой источник фотонов»",
          region: "Центральный регион",
          place: "Санкт-Петербург, Карла Либкнехта 12",
          image: "/frontend/assets/img/events/events-news-02.jpg",
        },
        {
          date: "2021-04-16 20:20",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }, { name: "Минпросвещения России" }],
          title: "Тематические выпускные: Всероссийский студенческий выпускной «С Наукой в сердце»; Всероссийский школьный выпускной",
          region: "Центральный регион",
          place: "Москва, Ленина 45/2, 5 этаж",
          image: "/frontend/assets/img/events/events-news-03.jpg",
        },
        {
          date: "2021-05-24 14:05",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }, { name: "Минпросвещения России" }],
          title: "Всероссийский открытый урок в День знаний «Современная наука»",
          region: "Центральный регион",
          place: "Москва, Ленина 45/2, 5 этаж",
          image: "/frontend/assets/img/events/events-news-04.jpg",
        },
        {
          date: "2021-04-30 19:10",
          period: "12 марта — 14 апреля 2021",
          organizers: [{ name: "Минобрнауки России" }],
          title: "Спуск на воду научно- исследовательского судна «Пионер-М»",
          region: "Центральный регион",
          place: "Санкт-Петербург, Карла Либкнехта 12",
          image: "",
        },
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.gallerySwiper.$swiper;
    },
    swiperOptions() {
      return {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        loop: true,
        watchSlidesVisibility: true,
        slideVisibleClass: "swiper-slide-visible",
        navigation: {
          prevEl: ".events-slider__prev",
          nextEl: ".events-slider__next",
          disabledClass: "is-disabled"
        },
        pagination: {
          el: ".events-slider__bullets",
          type: "bullets",
          bulletClass: "g-pagination-bullet",
          bulletActiveClass: "is-active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 30,
            pagination: {
              el: ".events-slider__count",
              type: "fraction"
            }
          },
          1440: {
            slidesPerView: "auto",
            spaceBetween: 40,
            pagination: {
              el: ".events-slider__count",
              type: "fraction"
            }
          }
        },
        on: {
          slideChange: function () {
            const prevIndex = this.activeIndex - 1;
            const slides = Object.values(this.slides);
            const prevSlide = slides[prevIndex];

            slides.forEach(element => {
              if (typeof(element) === "object") {
                const hasClass = element.classList.contains("swiper-real-prev");
                if (hasClass) {
                  element.classList.remove("swiper-real-prev");
                }
              }
            });
            prevSlide.classList.add("swiper-real-prev");
          },
        },
      }
    }
  },
  data() {
    return {
      items: this.initialItems
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).locale("ru").format("DD MMMM / HH:mm")
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".events-slider";

#{$b} {
  display: block;

  &__inner {
    position: relative;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
    width: 368px;
    transition: opacity 0.2s ease-out;

    @include mobile-min {
      opacity: 0;
      visibility: hidden;
    }

    &.swiper-real-prev {
      @include mobile {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.swiper-slide-visible {
      @include mobile-min {
        opacity: 1;
        visibility: visible;
      }
    }

    &.is-slide-background {
      width: 776px;
    }

    &.is-slide-large {
      width: 776px;

      &._additional,
      &._regional {
        @include desktop-min {
          #{$b}__item-date {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .events__item-additional {
            text-align: right;
            max-width: 170px;
            margin-top: 0;
          }
        }
      }
    }
  }

  &__controls-wrapper {
    position: absolute;
    left: 0;
    bottom: 6px;
    z-index: 10;

    @include desktop {
      bottom: 4px;
    }

    @include tablet {
      position: static;
      margin: 40px 0 0;
    }
  }

  &__controls {
    height: 0;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: inherit;

    @include desktop {
      width: 88px;
    }

    @include tablet {
      display: none;
    }

    .inline-svg {
      height: 100%;
    }

    .swiper-pagination {
      position: static;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &__prev,
  &__next {
    outline: 0;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    flex-shrink: 0;
    width: 20.5px;
    height: 11px;
    transition: 0.3s;
    color: inherit;

    @include desktop {
      width: 15.5px;
      height: 11px;
    }

    &:hover {
      &:not(.is-disabled) {
        color: $color-base;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.35;
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  &__bullets {

    @include tablet-min {
      display: none;
    }
  }

  &__item {
    position: relative;
    z-index: 0;
    min-height: 306px;
    height: 100%;
    display: flex;
    text-decoration: none;
    color: $color-black;
    background-color: rgba($color: $white-true, $alpha: 0.8);
    box-shadow: 0px 40px 80px rgba($color: $black-true, $alpha: 0.05);

    @include mobile {
      flex-direction: column;
    }

    .is-slide-background & {
      @include mobile-min {
        background-color: #133287;
        color: $white-true;
      }
    }

    .is-slide-colored & {
      color: $white-true;
    }

    &-body {
      flex: 1 1 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 36px 30px 30px 40px;

      @include desktop {
        padding: 30px 30px 25px 30px;
      }

      .is-slide-large & {
        @include desktop-min {
          background: linear-gradient(61.25deg, #EAEAEA 0%, $white-true 99.13%);
        }
      }

      .is-slide-colored & {
        background: #133287;
      }

      ._additional & {
        background: url(/frontend/assets/img/events/additional-bg.jpg) center !important;
        background-size: cover !important;
        color: $color-black !important;
      }

      ._regional & {
        background: url(/frontend/assets/img/events/regional-bg.jpg) center !important;
        background-size: cover !important;
        color: $white-true !important;
      }
    }

    &-date {
      display: block;
      margin-bottom: 24px;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    &-date-label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      border: 1.5px solid $color-base;
      border-radius: 30px;
      padding: 0 8px;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: inherit;
      white-space: nowrap;

      @include mobile {
        height: 24px;
        font-size: 10px;
        padding: 0 10px 0 3px;
        border-width: 1px;
        letter-spacing: 0.04em;
      }

      .inline-svg {
        width: 18px;
        height: 18px;
        color: $color-base;
        margin-right: 10px;

        @include mobile {
          margin-right: 5px;
        }
      }
    }

    &-organizers {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: inherit;
      opacity: 0.5;
      margin-bottom: 12px;

      &>span {
        &:first-child {
          @include mobile {
            display: block;
          }
        }
      }
    }

    &-title {
      display: block;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.4;
      margin: 0;

      @include desktop {
        font-size: 18px;
      }

      @include desktop {
        font-size: 16px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 1.5;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-place {
      margin-top: auto;
      display: block;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.6;

      @include desktop {
        font-size: 12px;
      }
    }

    &-media {
      background-color: #eee;
      flex-shrink: 0;

      @include mobile {
        order: -1;
      }

      @include mobile-min {
        display: none;
      }

      .is-slide-large & {

        @include desktop-min {
          display: block;
          width: calc(50% - 20px);
        }
      }

      .is-slide-colored & {
        background-color: #304d9a;
      }

      .is-slide-background & {
        @include mobile-min {
          display: block;
          background-color: #365380;

          @include full-abs;
          z-index: -1;

          &:before {
            content: "";
            background-color: #365380;
            opacity: .5;
            @include full-abs;
          }
        }
      }
    }

    &-image {
      display: block;
      width: 100%;
      height: 100%;
      @include bg-cover;

      @include mobile {
        padding-top: 56%;
      }
    }
  }
}
</style>
