<template>
  <nav class="universities-nav">
    <ul class="universities-nav__list" v-dragscroll.x:nochilddrag>
      <li class="universities-nav__item"
        v-for="item in navList"
        :key="item.title"
      >
        <component
          :is="item.url ? 'a' : 'span'"
          :href="item.url"
          class="universities-nav__link"
          :class="{
            'universities-nav__link--is-active': item.isActive,
            'universities-nav__link--is-disabled': !item.url
          }"
        >
          {{ item.title }}
        </component>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    scrollActiveIntoViev() {
      this.$nextTick(() => {
        const activeItem = this.$el.querySelector('.universities-nav__link--is-active')
        if (activeItem) {
          activeItem.scrollIntoView(false)
        }
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollActiveIntoViev()
    })
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.universities-nav';

#{$b} {
  // .universities-nav__list
  &__list {
    display: flex;
    align-items: center;
    overflow: auto;

    @include tablet {
      margin-left: #{-$container-pd-left-tablet};
      margin-right: #{-$container-pd-right-tablet};
      padding-left: $container-pd-left-tablet;
      padding-right: $container-pd-right-tablet;
    }

    @include mobile {
      margin-left: #{-$container-pd-left-mobile};
      margin-right: #{-$container-pd-right-mobile};
      padding-left: $container-pd-left-mobile;
      padding-right: $container-pd-right-mobile;
    }

    @include removeScrollBar();
  }

  // .universities-nav__item
  &__item {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  // .universities-nav__link
  &__link {
    cursor: pointer;
    display: block;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    color: rgba($color: $white-true, $alpha: 0.5);
    padding-bottom: 24px;
    white-space: nowrap;
    text-decoration: none;

    &--is-disabled {
      pointer-events: none;
      touch-action: none;
    }

    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background-color: transparent;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      transition: $transtion-default;
    }

    &--is-active {
      color: $white-true;

      &::after {
        background-color: $color-base !important;
      }
    }

    &:hover {
      @include mobile-min {
        &:after {
          background-color: rgba($color: $white-true, $alpha: 0.5);
        }
      }
    }
  }
}
</style>
