<template>
  <div
    v-if="MAIN_ITEMS?.length"
    class="header-menu"
    :class="[
      { 'header-menu--submenu-opened': isOpened, },
      `header-menu--mode-${mode}`
    ]"
    :style="`--animation-time: ${animationTime}ms`"
    @pointerleave="onBlurRoot"
  >
    <div class="header-menu__inner">
      <div class="header-menu__list">
        <div
          v-for="item in MAIN_ITEMS"
          :key="`header-menu__item-${item.id}`"
          class="header-menu__item header-menu__item--main"
          :class="{ 'header-menu__item--has-childs': item.childs?.length }"
        >
          <component
            :is="linkTag"
            tag="a"
            class="header-menu__link"
            :class="{
              'header-menu__link--focused': isFocusedBtn(item) || isVisibleMobileSubmenu(item.id),
              'header-menu__link--has-childs': item.childs?.length
            }"
            :size="isDefaultMode ? 'sm' : ''"
            :theme="isDefaultMode ? getBtnTheme(item) : ''"
            :no-hover="isDefaultMode ? isFocusedBtn(item) : ''"
            :href="item.url"
            :target="item.target"
            @pointerenter="onHover(item)"
            @click="(e) => onClick(e, item)"
          >
            <span v-html="item.name" />
            <span v-if="item.childs?.length > 0" class="header-menu__link-icon">
              <InlineSvg src="u-arr-down" />
            </span>
          </component>

          <div
            v-if="item.childs?.length > 0 && breakpoint.isMobile"
            class="header-menu__submenu"
            :class="{ 'header-menu__submenu--is-hidden': !isVisibleMobileSubmenu(item.id) }"
          >
            <div ref="submenuList" class="header-menu__submenu-list header-menu__list">
              <div
                v-for="item in item.childs"
                :key="`header-menu__submenu-item-${item.id}`"
                class="header-menu__submenu-item header-menu__item"
              >
                <component
                  :is="linkTag"
                  tag="a"
                  class="header-menu__submenu-link header-menu__link"
                  :size="isDefaultMode ? 'sm' : ''"
                  :theme="isDefaultMode ? 'outline-light-dark' : ''"
                  :no-hover="isDefaultMode ? item.isActive : false"
                  :href="item.url"
                  :target="item.target"
                >
                  <span v-html="item.name" />
                </component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="submenu && !breakpoint.isMobile"
      class="header-menu__submenu"
      :class="{
        'header-menu__submenu--is-hidden': !isOpened,
      }"
    >
      <div
        class="header-menu__submenu-list header-menu__list"
        :class="{ 'header-menu__submenu-list--align-right': focusedItem?.alignRight }"
      >
        <div
          v-for="item in submenu"
          :key="`header-menu__submenu-item-${item.id}`"
          class="header-menu__submenu-item header-menu__item"
        >
          <component
            :is="linkTag"
            tag="a"
            class="header-menu__submenu-link header-menu__link"
            :size="isDefaultMode ? 'sm' : ''"
            :theme="isDefaultMode ? (item.isActive ? 'light-black' : 'outline-light-dark') : ''"
            :no-hover="isDefaultMode ? item.isActive : false"
            :href="item.url"
            :target="item.target"
          >
            <span v-html="item.name" />
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['breakpoint'],

  props: {
    mode: {
      type: String,
      default: 'default' // default || burger
    }
  },

  data() {
    return {
      submenu: null,
      focusedId: 0,
      isOpened: false,
      animationTime: 500,
      timeoutId: null,
      mobileSubmenuIds: []
    }
  },

  computed: {
    ...mapGetters('header', ['MAIN_ITEMS']),
    isBurgerMode() {
      return this.mode === 'burger'
    },
    isDefaultMode() {
      return this.mode === 'default'
    },
    linkTag() {
      return this.isDefaultMode ? 'custom-button' : 'a'
    },
    focusedItem() {
      return this.MAIN_ITEMS.find(item => item.id === this.focusedId)
    }
  },

  methods: {
    setSubmenu(submenu, focusedId) {
      clearTimeout(this.timeoutId)

      const isOpened = submenu?.length > 0

      if (isOpened) {
        this.submenu = submenu
        this.focusedId = focusedId

        this.$nextTick().then(() => {
          setTimeout(() => {
            this.isOpened = true
          }, 10);
        })
      } else {
        this.isOpened = false

        this.timeoutId = setTimeout(() => {
          this.submenu = null
          this.focusedId = null
        }, this.animationTime);
      }
    },
    setMobileSubmenu(id) {
      this.mobileSubmenuIds = this.isVisibleMobileSubmenu(id)
        ? this.mobileSubmenuIds.filter(item => item !== id)
        : [...this.mobileSubmenuIds, id]
    },
    onHover(item) {
      if (this.breakpoint.isMobile) {
        return
      }

      const submenu = item?.childs?.length > 0 ? item.childs : null
      this.setSubmenu(submenu, item.id)
    },
    onClick(e, item) {
      if (item.childs?.length > 0) {
        e.preventDefault();
      }

      if (!this.breakpoint.isMobile || !item.childs?.length) {
        return
      }


      this.setMobileSubmenu(item.id)
    },
    onBlurRoot() {
      if (!this.isDefaultMode || this.breakpoint.isMobile) {
        return
      }
      this.setSubmenu(null, 0);
    },
    isFocusedBtn(item) {
      return item.isActive || (this.isOpened && item.childs.length > 0 && this.focusedId === item.id)
    },
    getBtnTheme(item) {
      return this.isFocusedBtn(item)
        ? 'light-black'
        : 'outline-light-dark'
    },
    isVisibleMobileSubmenu(id) {
      return this.mobileSubmenuIds.includes(id)
    },
    checkSubmenulistHeight() {
      if (!this.$refs.submenuList || !this.MAIN_ITEMS?.length || !this.breakpoint.isMobile) {
        return
      }

      this.$refs.submenuList?.forEach((submenu) => {
        submenu.style.setProperty('--max-height', `${Math.ceil(submenu.scrollHeight)}px`);
      })
    }
  },

  mounted() {
    this.$nextTick().then(this.checkSubmenulistHeight)
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.header-menu';
$gap: 4px;

#{$b} {
  position: relative;
  width: 100%;

  // .header-menu--mode-burger
  &--mode-burger {
    display: grid;
    grid-template-columns: auto 246px;
    gap: 39px;

    @include low-desktop {
      gap: 24px;
    }

    @include mobile {
      display: block;
    }
  }

  // .header-menu__inner
  &__inner {
    transition: $transtion-default;


    #{$b}--mode-default & {
      padding-bottom: 17px;
    }
  }

  // .header-menu__list
  &__list {

    #{$b}--mode-default & {
      display: flex;
      flex-wrap: wrap;
      margin: -#{$gap};
    }
  }

  // .header-menu__item
  &__item {
    #{$b}--mode-default & {
      padding: $gap;
    }

    #{$b}--mode-burger & {
      font-family: $font-family-fugue;
      font-weight: 500;
      line-height: 100%;
      text-transform: uppercase;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      // .header-menu__item--has-childs
      &--has-childs {
        overflow: hidden;
      }
    }

    // .header-menu__item--main
    &--main {
      #{$b}--mode-burger & {
        display: flex;
        align-items: center;
        font-size: 24px;

        @include mobile {
          display: block;
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }

  // .header-menu__link
  &__link {

    #{$b}--mode-burger & {
      display: inline-flex;
      align-items: center;
      color: $white-true;
      transition: $transtion-default;
      text-decoration: none;

      @include hover {
        color: $color-base-origin;
      }
    }

    // .header-menu__link--has-childs
    &--has-childs {
      #{$b}--mode-burger & {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: calc(100% + 40px);
          width: 100vw;
          height: 1px;
          background-color: rgba($white-true, 0.35);
          transform: translateY(-50%);
          opacity: 0;
          pointer-events: none;
          touch-action: none;
          transition: opacity 0.3s ease;

          @include low-desktop {
            left: calc(100% + 20px);
          }

          @include mobile {
            display: none;
          }
        }
      }
    }

    // .header-menu__link--focused
    &--focused {

      #{$b}__link-icon {
        svg {
          transform: rotate(180deg);
        }
      }

      #{$b}--mode-burger & {
        @include mobile-min {
          color: $color-base-origin;
        }

        &::after {
          opacity: 1;

          @include mobile {
            display: none;
          }
        }
      }
    }

    // .header-menu__link-icon
    &-icon {
      display: inline-block;

      #{$b}--mode-default & {
        margin-left: 6px;
      }

      #{$b}--mode-burger & {
        margin-left: 10px;
      }

      svg {
        width: 12px;
        height: 6px;
        transition: transform var(--animation-time) ease;
      }

      path {
        fill: transparent !important;
        stroke: currentColor;
      }
    }
  }

  // .header-menu__submenu
  &__submenu {
    @include mobile {
      transition: $transtion-default;
      overflow: hidden;
      padding-left: 50px;
      padding-top: 24px;

      @include removeScrollBar;
    }

    #{$b}--mode-default & {
      width: 100%;
      padding-bottom: 70px;

      @include mobile {
        padding-bottom: 0;
      }
    }

    // .header-menu__submenu--is-hidden
    &--is-hidden {
      pointer-events: none;
      touch-action: none;

      #{$b}__submenu-list {
        @include mobile-min {
          transform: translateY(50%);
          opacity: 0;
        }
      }

      @include mobile {
        padding: 0;
        margin: 0;
        opacity: 0;

        #{$b}__submenu-list {
          max-height: 0;
        }
      }
    }

    &:not(#{$b}__submenu--is-hidden) {
      @include mobile {
        opacity: 1;

        #{$b}__submenu-list {
          max-height: var(--max-height);
        }
      }
    }

    // .header-menu__submenu-list
    &-list {
      transition: all var(--animation-time) ease;

      // .header-menu__submenu-list--align-right
      &--align-right {
        #{$b}--mode-default & {
          justify-content: flex-end;

          @include low-desktop {
            justify-content: flex-start;
          }
        }
      }
    }

    // .header-menu__submenu-item
    &-item {
      #{$b}--mode-burger & {
        font-size: 18px;

        @include mobile {
          font-size: 16px;
          line-height: 21px;
        }
      }

      animation: opacityAnimation 0.5s ease;
    }
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 0
  } 100% {
    opacity: 1;
  }
}
</style>
