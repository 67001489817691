<template>
  <section class="video-page">
    <u-figures
      :items="figures"
      :full-width="true"
      position="static"
    />
    <div class="video-page__inner">
      <div class="u-g-container">
          <div class="video-page__header">
            <h2 class="video-page__title tt-uppercase title-xl">
              {{ title }}
            </h2>
            <FilterTags
              v-model="selectedThemes"
              @input="changeFilter"
              :list="getThemesList"
              class="video-page__filter"
            />
          </div>
          <div class="video-page__body">
            <CustomVideoCards v-if="isExistsPage" :videoList="videoList" ref="videoList"/>
            <not-found v-else text="По данным параметрам ничего не найдено" />
          </div>
          <div class="video-page__footer" v-show="paginator.NavPageNomer < paginator.NavPageCount">
            <CustomButton
              class="video-page__show-more"
              size="md"
              theme="primary"
              @click="showNewCards(false)"
            >
              Показать ещё
            </CustomButton>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    title: {
      type: String,
      default: 'ПОПУЛЯРНЫЕ ТЕМЫ'
    },
    themesList: {
      type: Array,
      default: () => []
    },
    initialVideoList: {
      type: Array,
      default: () => []
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => []
    },
  },
  data() {
    return {
      selectedThemes: [],
      nextUrl: this.initNextUrl,
      paginator: JSON.parse(JSON.stringify(this.initPaginator)),
      videoList: JSON.parse(JSON.stringify(this.initialVideoList)),
    }
  },
  computed: {
    isExistsPage() {
      return Boolean(this.videoList.length)
    },
    figures() {
      const figures = [
        {
          name: 'f-video-page-circle',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left',
        },
        {
          name: 'f-video-page-lines',
          image: '/frontend/assets/img/bg-shapes/lines.svg',
          animation: 'fade-in-left',
        },
      ]
      return this.videoList.length >= 4 ? figures : [ figures[0] ]
    },
    getThemesList() {
      return this.themesList.map(item => {
        const isActive = this.selectedThemes.includes(item.id)

        return {
          ...item,
          isActive: item.isActive || isActive,
        }
      })
    }
  },
  methods: {
    async showNewCards(clearItems){
      const bottom = this.$refs.videoList.$el.getBoundingClientRect().bottom
      await this.loadMoreItems(clearItems)
      window.scrollTo({
        top:  window.scrollY + bottom,
        behavior: 'smooth'
      });
    },
    async loadMoreItems(clearItems) {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      if (clearItems) {
        this.videoList = [];
      }

      res.data.ITEMS.map(item => {
        this.videoList.push(item);
      });
    },
    async onLoadVideos() {
      const lines = [];
      if (this.selectedThemes.length) {
        this.selectedThemes.map(id => {
          lines.push(`sid[]=${id}`);
        });
      }

      const params = lines.join("&");
      this.nextUrl = `?AJAX=Y&PAGEN_${this.paginator.NavNum}=1&${params}`;
      this.loadMoreItems(true);
    },
    changeFilter() {
      this.onLoadVideos()
    }
  },
}
</script>


<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.video-page';

#{$b} {
  font-family: $font-family-inter;
  font-weight: 400;
  color: $black-true;
  padding: 64px 0 128px;
  background-color: $color-secondary;
  position: relative;

  // .video-page__inner
  &__inner {
    position: relative;
    z-index: 1;
  }

  @include mobile {
    padding: 48px 0 64px;
  }

  // .video-page__title
  &__title {
    font-family: $font-family-fugue;
    margin: 0 0 32px 0;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  // .video-page__header
  &__header {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 32px;
    }
  }

  // .video-page__body
  &__body {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .video-page__footer
  &__footer {
    @include mobile {
      text-align: center;
    }
  }
}
</style>
