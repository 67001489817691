<template>
  <div class="u-header-tabs">
    <div class="u-header-tabs__top">
      <div
        class="u-header-tabs__overflow"
        :class="{ 'u-header-tabs__overflow--hide-on-mobile': dropDownOnMobile }"
        v-dragscroll.x:nochilddrag
      >
        <ul class="u-header-tabs__list">
          <li v-for="item in tabs" :key="item.tabId">
            <div
              class="u-header-tabs__item"
              :class="{ 'is-active': item.isActive }"
              @click.prevent="selectTab(item.tabId)"
            >
              {{ item.title }}
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="isExistsMobileTabs"
        ref="dropdown"
        class="u-header-tabs__dropdown"
        :class="{ 'u-header-tabs__dropdown--is-fixed': isFixedDropdown }"
        :style="dropdownStyle"
      >
        <div class="u-header-tabs__dropdown-wrapper" ref="dropdownWrapper">
          <TabListDropdown
            ref="dropdownBlock"
            class="u-header-tabs__dropdown-block"
            uniqueKey="tabId"
            theme="light-primary"
            size="lg"
            :tabs="tabs"
            :value="activeTab.tabId"
            :isStatic="isFixedDropdown"
            @input="selectTab"
          />
        </div>
      </div>
      <div class="u-header-tabs__container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    inject: ['breakpoint'],
    name: "HeaderTabs",
    props: {
      dropDownOnMobile: {
        type: Boolean,
        default: false
      },
      fixedMob: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        tabs: [],
        isFixedDropdown: false,
        offsetDropdownWrapper: 12
      };
    },
    mounted() {
      this.initTabs()
      this.$root.$on('header-tabs-change', this.selectTab);

      if (this.fixedMob) {
        window.addEventListener('scroll', this.checkScroll)
      }

      setTimeout(() => {
        this.scrollActiveIntoViev()
      }, 500)
    },
    computed: {
      isExistsMobileTabs(){
        return (this.dropDownOnMobile && this.tabs && this.tabs.length) ? true : false
      },
      activeTab() {
        if (!this.isExistsMobileTabs) {
          return
        }

        return this.tabs.find(tab => tab.isActive)
      },
      dropdownStyle() {

        return {
          '--offset': `${this.offsetDropdownWrapper}px`
        }
      }
    },
    methods: {
      selectTab(tabId) {
        this.tabs.forEach(tab => {
          tab.isActive = tab.tabId === tabId;

          if (tab.isActive) {
            this.saveTab(tab.tabId)
          }
        });
        this.$root.$emit('header-tab-change');
      },
      openSavedTab() {
        const params = (new URL(document.location)).searchParams;
        const tabParam = params.get("tab")

        if (tabParam) {
          this.$root.$emit('header-tabs-change', tabParam)
        }
      },
      saveTab(tabId) {
        const baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        const newUrl = `${baseUrl}?tab=${tabId}`;
        history.pushState(null, null, newUrl);
      },
      scrollActiveIntoViev() {
        this.$nextTick(() => {
          const activeItem = this.$el.querySelector('.u-header-tabs__item.is-active')
          if (activeItem) {
            activeItem.scrollIntoView(false)
          }
        })
      },
      initTabs() {
        if (!this.$slots.default.length) {
          return
        }

        this.tabs = this.$slots.default
          .map(item => item.child)
          .filter(item => item)
      },
      checkScroll() {
        if (!this.breakpoint.isMobile) {
          return
        }

        const dropdownEl = this.$refs.dropdown
        const dropdownBlockEl = this.$refs.dropdown ? this.$refs.dropdownBlock.$el : null

        if (!this.fixedMob || !dropdownEl || !dropdownBlockEl) {
          this.isFixedDropdown = false
          return
        }

        const { top } = dropdownEl.getBoundingClientRect()
        const { height: dropdownBlockHeight } = dropdownEl.getBoundingClientRect()


        this.isFixedDropdown = top < this.offsetDropdownWrapper

        dropdownEl.style.height = this.isFixedDropdown ? `${dropdownBlockHeight}px` : ''
      }
    },
  };
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: '.u-header-tabs';
$offsetTop: 47px;
$offsetMob: 47px;

#{$b} {
  display: block;

  &__wrapper {
    padding-top: 0;
    padding-bottom: 128px;
    margin-top: -#{$offsetTop};
    position: relative;

    @include mobile {
      margin-top: calc(-#{$offsetMob} + 2px);
      padding-bottom: 64px;
    }
  }

  &__overflow {
    min-width: 100%;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    box-sizing: border-box;
    position: relative;
    z-index: 2;

    @include tablet {
      margin-left: #{-$container-pd-left-tablet};
      margin-right: #{-$container-pd-right-tablet};
      padding-left: $container-pd-left-tablet;
      padding-right: $container-pd-right-tablet;
    }

    @include mobile {
      margin-left: #{-$container-pd-left-mobile};
      margin-right: #{-$container-pd-right-mobile};
      padding-left: $container-pd-left-mobile;
      padding-right: $container-pd-right-mobile;
    }

    &--hide-on-mobile {
      @include mobile {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: flex-end;
    min-width: 100%;

    &>li {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &__dropdown {
    @include mobile-min {
      display: none;
    }

    &-wrapper {
      position: relative;
      z-index: 3;

      #{$b}__dropdown--is-fixed & {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: var(--offset);
        background-color: $white-true;
      }
    }

    &-block {

      #{$b}__dropdown--is-fixed & {
        border: 1px solid $color-primary;
      }

      @include mobile-min {
        display: none;
      }

      @include mobile {
        border: 1px solid transparent;
        border-radius: 20px;
      }
    }
  }

  &__item {
    cursor: pointer;
    display: block;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    color: rgba($color: $white-true, $alpha: 0.5);
    padding-bottom: 24px;
    white-space: nowrap;

    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background-color: transparent;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.is-active {
      color: $white-true;

      &::after {
        background-color: $color-base;
      }
    }
  }
}
</style>
