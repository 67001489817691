var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-events"},[_c('div',{staticClass:"u-events-page__bg-circle-1"}),_c('u-figures',{attrs:{"items":[
      {'name':'f-events-page-mob-1','image':'/frontend/assets/img/bg-shapes/circle-group-left-opacity.svg','animation':'fade-in-left'},
      {'name':'f-events-page-mob-2','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
      {'name':'f-events-page-mob-3','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
      {'name':'f-events-page-mob-4','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
      {'name':'f-events-page-mob-5','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
    ],"fullWidth":true,"position":"static"}},[_c('div',{staticClass:"u-events__wrapper"},[_c('u-events-filter',{staticClass:"u-events__filter",attrs:{"initial-filters":_vm.initParams.initialFilters,"event-type-options":_vm.eventTypeOptions,"partners-options":_vm.partnersOptions,"region-options":_vm.regionsOptions,"year-options":_vm.yearOptions,"initiatives-options":_vm.initiativesOptions,"audience-options":_vm.audienceOptions},on:{"change":_vm.onFilterChange}}),_c('div',{staticClass:"u-events__body"},[(_vm.items.length)?_c('UEventsList',{ref:"eventsList",attrs:{"items":_vm.items,"isLinks":""}}):_c('not-found',{attrs:{"text":"По данным параметрам ничего не найдено"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paginator.NavPageNomer < _vm.paginator.NavPageCount),expression:"paginator.NavPageNomer < paginator.NavPageCount"}],staticClass:"u-events__load-more"},[_c('CustomButton',{attrs:{"theme":"primary","size":"md"},on:{"click":function($event){return _vm.showNewCards(false)}}},[_vm._v(" Показать ещё ")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }