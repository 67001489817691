<template>
  <div class="auth">
    <template v-if="activeForm === 'authorization'">
      <div class="auth-title tt-uppercase">Вход</div>
      <auth-form @onChangeForm="changeForm" @showAcceptPolicyModal="showAcceptPolicyModal" :acceptPolicy="checked" :bus="bus"/>
    </template>
    <template v-if="activeForm === 'registration'">
      <div class="auth-title tt-uppercase">Регистрация</div>
      <reg-form @onChangeForm="changeForm"/>
    </template>
    <template v-if="activeForm === 'recovery'">
      <div class="auth-title auth-title--small tt-uppercase">Восстановление пароля</div>
      <pass-recovery @onChangeForm="changeForm"/>
    </template>
    <div class="auth-info" v-if="showInfo">
      <div class="auth-info__text">
        <span>В целях соблюдения законодательства РФ в <a href="/upload/documents/Политика_конфиденциальности_годнауки.pdf" target="_blank">Политику обработки персональных данных</a> сайта внесены изменения, которые связаны в том числе с использованием системы аналитики Google Analytics.</span>
        <br>
        <span>Просим Вас ознакомиться с документом и подтвердить своё согласие на трансграничную передачу персональных данных при наступлении такой необходимости</span>
      </div>
      <div class="auth-info__verification">
        <div content="auth-info__verification-input">
          <input type="checkbox" v-model="checked" id="verification">
          <inline-svg v-if="checked" @click="checked = false" src="checkmark-icon" />
        </div>
        <label for="verification">Я даю согласие на трансграничную передачу своих персональных данных</label>
      </div>
      <button @click="acceptConditions" type="button" class="button button--base">Ок</button>
      <div class="v-modal__close" @click="closeModal">
        <inline-svg src="close-thin" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"

export default {
  name: "Authorization",
  data: function () {
    return {
      activeForm: "authorization",
      showInfo: false,
      checked: false,
      bus: null,
    };
  },
  props: {
    confirm: { type: Boolean, default: false },
    startForm: { type: String, default: "authorization" },
  },
  created() {
    this.bus = new Vue();
  },
  methods: {
    changeForm(name) {
      this.activeForm = name;
    },
    showAcceptPolicyModal() {
      this.showInfo = true;
    },
    showIn() {
      !this.confirm ? this.showInfo = true : '';
    },
    acceptConditions() {
      if (this.checked) {
        // если согласился скрываем диалог
        this.showInfo = false;

        // и сабмитим логин форму (send event to submit form login)
        this.bus.$emit('submitLogin');
      }
    },
    closeModal() {
      this.showInfo = false;
    },
  },
  mounted() {
    this.$on('show', this.showIn);

    this.$root.$on('modal-cancel', () => {
      this.closeModal();
    });

    this.activeForm = this.startForm;
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".auth";

#{$b} {
  position: relative;

  &-title {
    font-family: $font-family-fugue;
    display: block;
    font-weight: 500;
    font-size: 44px;
    line-height: 1;
    color: $black-true;
    padding-right: 30px;
    margin: 0 0 32px;

    @include mobile {
      font-size: 28px;
    }

    &--small {
      font-size: 32px;

      @include mobile {
        font-size: 20px;
      }
    }
  }

  &-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white-true;
    padding-top: 35px;
    z-index: 10;

    &__text {
      margin-bottom: 15px;

      span {
        font-size: 15px;
      }
    }

    &__verification {
      display: flex;
      margin-bottom: 15px;
      font-size: 14px;

      > div {
        position: relative;

        .inline-svg {
          position: absolute;
          left: -2px;
          top: 2px;
          width: 18px;
          height: 18px;
          color: #ffffff;
        }
      }

      input {
        margin-right: 15px;
        margin-top: 5px;
        position: relative;

        &:checked:before {
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #5893FB;
          border-radius: .25em;
        }
      }
    }

    button {
      width: 100%;
    }

    .v-modal__close {
      right: 0;
      top: 0;
    }
  }
}
</style>
