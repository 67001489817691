<template>
  <section class="talisman-works">
    <div class="u-g-container">
      <UFigures
        :items="figures"
        :fullWidth="true"
        :isAnimateImg="false"
        position="static"
        class="talisman-works__figures"
      />
      <div class="talisman-works__inner">
        <div class="talisman-works__header">
          <h2 class="talisman-works__title tt-uppercase title-xl">
            {{ title }}
          </h2>
        </div>
        <div class="talisman-works__content">
          <div v-if="info && info.left" class="talisman-works__content-item">
            <div class="talisman-works__content-info">
              <p
                v-for="(text, i) in info.left"
                :key="`talisman-works__content-text-left-${i}`"
                v-html="text"
                class="talisman-works__content-text text-ll"
              />
            </div>
          </div>
          <div v-if="info && info.right" class="talisman-works__content-item">
            <div class="talisman-works__content-info">
              <p
                v-for="(text, i) in info.right"
                :key="`talisman-works__content-text-right-${i}`"
                v-html="text"
                class="talisman-works__content-text text-ll"
              />
            </div>
            <CustomButton
              theme="light-black"
              size="md"
              class="talisman-works__regulations-btn"
              tag="a"
              href="/upload/documents/Правила_Конкурса_Талисман_Десятилетия_науки_и_технологий.pdf"
              target="_blank"
            >
              Подробные правила конкурса
            </CustomButton>
          </div>
        </div>
        <div v-if="additionTitle || additionText" class="talisman-works__addition">
          <div class="talisman-works__addition-content">
            <h3 v-if="additionTitle" v-html="additionTitle" class="talisman-works__addition-title tt-uppercase title-xl" />
            <p v-if="additionText" v-html="additionText" class="talisman-works__addition-text text-lg" />
          </div>
          <!-- <div class="talisman-works__addition-actions">
            <SuggestTalismanBtn
              class="talisman-works__addition-suggest"
              metricPos="middle"
            >
              <CustomButton
                theme="primary"
                size="md"
                class="talisman-works__addition-btn"
              >
                Предложить Талисман
              </CustomButton>
            </SuggestTalismanBtn>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    additionTitle: {
      type: String,
      default: ''
    },
    additionText: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      figures: [
        {
          name: 'f-talisman-works',
          image: '/frontend/assets/img/talisman/bubles-group.svg',
          animation: 'fade-in-left'
        }
      ]
    }
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.talisman-works';

#{$b} {
  position: relative;
  color: $white-true;
  font-family: $font-family-inter;
  background-color: $color-base-origin;
  padding: 64px 0;
  overflow: hidden;

  @include mobile {
    padding: 32px 0;
  }

  // .talisman-works__figures
  &__figures {
    @include mobile {
      display: none;
    }
  }

  // .talisman-works__inner
  &__inner {
    position: relative;
    z-index: 1;
  }

  // .talisman-works__header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  // .talisman-works__title
  &__title {
    font-family: $font-family-fugue;
    margin: 0 20px 0 0;
  }

  // .talisman-works__content
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 58px;

    &:not(:last-child) {
      margin-bottom: 48px;

      @include mobile {
        margin-bottom: 24px;
      }
    }

    @include mobile {
      display: block;
    }

    // .talisman-works__content-item
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mobile {
        display: block;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }

    // .talisman-works__content-text
    &-text {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  // .talisman-works__regulations-btn
  &__regulations-btn {
    align-self: flex-start;
    margin-left: auto;
    margin-top: 24px;
    margin-right: 31px;

    @include low-desktop {
      margin-right: 48px;
    }

    @include tablet {
      margin-right: 24px;
    }

    @include mobile {
      margin-right: 0;
      width: 100%;
    }
  }

  // .talisman-works__addition
  &__addition {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 45px 31px 47px 64px;
    background-color: $white-true;
    color: $black-true;
    border-radius: 40px;

    @include low-desktop {
      padding: 48px;
    }

    @include tablet {
      padding: 24px;
    }

    @include mobile {
      border-radius: 16px;
      display: block;
    }

    // .talisman-works__addition-content
    // &-content {
    //   max-width: 886px;
    //   padding-right: 30px;

    //   @include mobile {
    //     padding-right: 0;
    //     margin-bottom: 24px;
    //   }
    // }

    // .talisman-works__addition-title
    &-title {
      font-family: $font-family-fugue;
      margin: 0 0 24px 0;

      @include mobile {
        margin-bottom: 16px;
      }
    }

    // .talisman-works__addition-text
    &-text {
      margin: 0;

      @include mobile {
        font-size: 16px;
      }
    }

    // .talisman-works__addition-actions
    &-actions {
      text-align: right;
    }

    // .talisman-works__addition-suggest,
    // .talisman-works__addition-btn
    &-btn,
    &-suggest {
      @include mobile {
        width: 100%;
      }
    }
  }
}
</style>
